import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

const SearchSuggestionBackground: React.FC<BoxProps> = ({
	children,
	...rest
}) => {
	return (
		<Box
			height={'max-content'}
			width={'100%'}
			display='flex'
			flexDir={'column'}
			padding={{ base: '20px 20px', lg: '26px 32.303px 26px 31.697px' }}
			justifyContent='center'
			alignItems='center'
			borderRadius='24px'
			backdropFilter='blur(16.5px)'
			{...rest}
			background={
				rest.background ? rest.background : 'rgba(105, 105, 105, 0.13)'
			}>
			{children}
		</Box>
	);
};

export default SearchSuggestionBackground;
