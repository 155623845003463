import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { pixels } from '../../theme';

interface TrSecondaryButtonProps extends ButtonProps {
	children: React.ReactNode;
}

const TrSecondaryButton: React.FC<TrSecondaryButtonProps> = ({
	children,
	...rest
}) => {
	return (
		<Button
			as={Button || Link}
			colorScheme='secondary'
			display='flex'
			w={{ base: '68px', lg: '109px' }}
			height={{ base: pixels['30pixels'], lg: '48px' }}
			color='white'
			padding='10px 32px'
			justifyContent='center'
			alignItems='center'
			gap='10px'
			borderRadius='99px'
			border='1px solid #515151'
			background='rgba(238, 238, 238, 0.13)'
			_hover={{
				background: 'rgba(238, 238, 238, 0.3)',
			}}
			_active={{
				background: 'rgba(238, 238, 238, 0.5)',
			}}
			{...rest}>
			{children}
		</Button>
	);
};

export default TrSecondaryButton;
