import { Box, Text } from '@chakra-ui/react';

function WebsiteFooter() {
	return (
		<Box
			height={'50px'}
			width={'100%'}
			textAlign={'center'}
			paddingY={3}
			backgroundColor={'gray.800'}
			color={'white'}>
			<Text fontSize={'lg'}>&copy; 2024 Travibe AI Private Limited</Text>
		</Box>
	);
}

export default WebsiteFooter;
