import { VStack, HStack, Text, Container } from "@chakra-ui/react";

const HomePageFooterWeatherCard = ({weatherInfo}) => {
  return (
    <VStack
      width={"100%"}
      pt={"10px"}
      borderRadius={"20px"}
      bg={"#111012"}
      pb={"10px"}
    >
      <HStack width={"100%"}>
        <Container w={"50%"}>
          <Text fontWeight={"bold"}>Weather</Text>
          <Text fontWeight={"bold"} fontSize={"xx-large"}>
            {weatherInfo['temperature']}°C
          </Text>
          <Text color={"#6d6d6d"} fontSize="xs">
            {weatherInfo['description']}
          </Text>
        </Container>
        <Text
          fontSize="xs"
          w={"50%"}
          noOfLines={"4"}
          color={"#aaa7a7"}
          m={"10px"}
        >
          {weatherInfo['summary']}
        </Text>
      </HStack>
    </VStack>
  );
};

export default HomePageFooterWeatherCard;
