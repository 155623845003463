import React, { useEffect, useState } from 'react';
import '@fontsource/sacramento';
import { Box, Text, VStack } from '@chakra-ui/react';
import ItineraryCard from './ItineraryCard';
import data from './data.json'; // Ensure this path is correct
import { useParams } from 'react-router-dom';

interface TripDetailProps {
  title: string;
  subtitle: string;
  description: string;
}

const TripDetail: React.FC<TripDetailProps> = ({
  title,
  subtitle,
  description,
}) => {
  const { id } = useParams<{ id: string }>();
  const [itinerary, setItinerary] = useState<any[]>([]);

  useEffect(() => {
    if (id) {
      // Convert ID to number if necessary
      const selectedId = parseInt(id, 10);
      const selectedDestination = data.destinations.find((dest) => dest.id === selectedId);
      if (selectedDestination) {
        setItinerary(selectedDestination.itinerarydetail);
      }
    }
  }, [id]);

  return (
    <Box
      bg="none"
      color="white"
      p={4}
      rounded="lg"
      maxW={["auto", "auto", "inherit"]}
      mx="auto"
    >
      {/* Header Section */}
      <VStack align="start" mb={8}>
        <Box>
          <Text
            fontFamily={'Sacramento'}
            fontSize={'36px'}
            color={["#FFFFFFD4", "#FFFFFFD4", "#878787"]}
            textTransform={"lowercase"}
          >
            {title}
          </Text>
          <Text
            zIndex={2}
            mt={"-7"}
            fontSize="57px"
            fontWeight="700"
            bg="linear-gradient(180deg, rgba(255, 255, 255, 0.93) 53.49%, rgba(57, 55, 55, 0.93) 95.69%)"
            bgClip="text"
          >
            {subtitle}
          </Text>
        </Box>
        <Text
          fontSize="md"
          color="#FFFFFFA1"
          noOfLines={5}
          textAlign={'justify'}
        >
          {description}
        </Text>
        {itinerary.map((dayItinerary, dayIndex) => (
          <ItineraryCard
            key={dayIndex}
            itineraryData={dayItinerary}
          />
        ))}
      </VStack>
    </Box>
  );
};

export default TripDetail;
