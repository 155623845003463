import { InputProps, Input as ChakraInput } from '@chakra-ui/react';
import React, { Ref } from 'react';
import { colors, pixels } from '../theme';

interface UIInputProps extends Omit<InputProps, 'onChange' | 'placeholder'> {
	isDisable?: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
	ref?: Ref<HTMLInputElement>;
	[key: string]: any;
}

const UIInput: React.FC<UIInputProps> = ({
	height,
	width,
	borderRadius,
	border,
	isDisable,
	onChange,
	placeholder,
	children,
	isInvalid,
	...rest
}) => {
	const style = {
		color: colors.trGreyFont,
		border: `1px solid ${!isInvalid ? colors.trGrey : 'red'}`,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: '#fff',
		_focus: {
			border: `1px solid ${colors.trPrimary}`,
			boxShadow: 'none',
		},
	};

	const inputWidth = width || '100%';
	const inputHeight = height || '50px';
	const inputBorderRadius = borderRadius || '10px';

	return (
		<ChakraInput
			{...rest}
			{...style}
			color='trTextColor'
			variant={'trTextColor'}
			borderColor={!isInvalid ? colors.trGrey : 'red'}
			h={inputHeight}
			w={inputWidth}
			borderRadius={inputBorderRadius}
			isDisabled={isDisable}
			// border={inputBorder}
			onChange={onChange}
			placeholder={placeholder}
			_placeholder={{
				color: colors.trGreyFont,
				fontWeight: 'medium',
				fontSize: pixels['16pixels'],
			}}
		/>
	);
};

export default UIInput;
