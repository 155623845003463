import { Flex } from "@chakra-ui/react";
import { useContext, useEffect , useState } from "react";
import { AppContext } from "../context/AppContext";
import SavedPlacesCard from "./SavedPlacesCard";

const SavedPlaces = () => {
  const context = useContext(AppContext);
  const { bucketListCard, likeEntities } = context;

  const [savedPlaces , setSavedPlaces] = useState([])
    useEffect(()=>{
        const tempSavedPlaces = []
        for(let entity of likeEntities){
            if(entity['country']===bucketListCard[0] && entity['city']===bucketListCard[1]){
                tempSavedPlaces.push(entity)
            }
        }
        setSavedPlaces(tempSavedPlaces)
    },[likeEntities , bucketListCard])

  return (
    <Flex w={"100%"} wrap={"wrap"} gap={"40px"} mt={"20px"}>

    {
        savedPlaces.map((place , index)=>{
            return(
                <SavedPlacesCard
                previewImage = {place.previewImage}
                title = {place.title}
                description={place.description}
                vibe = {place.tags}
                city = {place.city}
                />
            )
        })
    } 
    </Flex>
  );
};

export default SavedPlaces;
