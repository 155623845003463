import { useEffect, useState } from 'react';
import {
	Box,
	Container,
	Heading,
	Stack,
	FormControl,
	FormHelperText,
	Alert,
	AlertIcon,
	HStack,
	PinInput,
	PinInputField,
	useToast,
} from '@chakra-ui/react';
import { pixels } from '../theme';
import TrButton from '../components/TrButton';
import TraverseLogo from '../components/TraverseLogo';
import useAxios from '../hooks/useAxios';
import { useLocation, useNavigate } from 'react-router-dom';
import ChangePasswordForm from '../components/ChangePasswordForm';

const VerifyCode: React.FC = () => {
	const { data, loading, fetchData } = useAxios();
	const [pin, setPin] = useState<string>();
	const [errors, setErrors] = useState<any>({});
	const [serverError, setServerError] = useState<string>('');
	const [showPasswordForm, setShowPasswordForm] = useState<boolean>(false);
	const location = useLocation();
	const type = location?.state?.type;
	const navigate = useNavigate();
	const toast = useToast();

	useEffect(() => {
		if (!location.state?.id) {
			navigate('/');
		}
	}, [location, navigate]);
	const setPinValue = (value: any) => {
		setErrors({});
		setServerError('');
		setPin(value);
	};

	const sendVerifyRequest = async () => {
		await setErrors({});
		console.log('pin', pin?.length);
		console.log(errors);
		if (!pin || pin.length < 6) setErrors({ pin: 'pin is required' });

		if (Object.keys(errors).length === 0) {
			const { error, statusCode } = await fetchData({
				method: 'POST',
				url: `verify/${type}`,
				options: {
					data: { id: location.state?.id, verification_code: pin },
					headers: { 'Content-Type': 'application/json' },
				},
				auth: false,
			});
			console.log('data', data);
			if (statusCode === 200) {
				setErrors({});
				toast({
					title: 'Congratulations!!!',
					description: `${String(type.replace(/_/g, ' '))} done`,
					status: 'success',
					duration: 9000,
					isClosable: true,
					position: 'top-right',
				});
				if (type === 'password_reset_request') return setShowPasswordForm(true);
				setPin('');
				navigate('/');
			} else {
				const errorMessage = error ? error?.error || error?.errors : null;
				if (errorMessage === null) {
					setServerError('There was an error processing your request');
				} else {
					setServerError(errorMessage);
				}
			}
		} else {
			//
		}
	};

	return (
		<Container
			color='trTextColor'
			background={'#fcfdfe'}
			h='100vh'
			maxW='lg'
			py={{ base: '12', md: '24' }}
			px={{ base: '0', sm: '8' }}>
			{!showPasswordForm ? (
				<Stack spacing='8'>
					<Stack spacing='6'>
						<Stack spacing={{ base: '2', md: '3' }} textAlign='center'>
							<Box mb={pixels['20pixels']}>
								<TraverseLogo />
							</Box>
							<Heading fontSize={{ base: pixels['20pixels'] }}>
								{String(type.replace(/_/g, ' ').toUpperCase()) || 'Verify Code'}
							</Heading>
						</Stack>
					</Stack>
					{serverError ? (
						<Alert status='error'>
							<AlertIcon />
							{JSON.stringify(serverError)}
						</Alert>
					) : (
						''
					)}
					<Box
						backgroundColor={'trWhiteColor'}
						py={{ base: '0', sm: '8' }}
						px={{ base: '4', sm: '10' }}
						bg={{ base: 'transparent', sm: 'bg.surface' }}
						boxShadow={{ base: 'none', sm: 'md' }}
						borderRadius={{ base: 'none', sm: 'xl' }}>
						<Stack spacing='6'>
							<Stack spacing='5'>
								<FormControl>
									<HStack align={'center'} justify={'center'}>
										<PinInput
											size={'lg'}
											otp
											onChange={setPinValue}
											value={pin}
											isInvalid={errors.phone_number}
											isDisabled={loading}>
											<PinInputField />
											<PinInputField />
											<PinInputField />
											<PinInputField />
											<PinInputField />
											<PinInputField />
										</PinInput>
									</HStack>
									<FormHelperText color={'red'}>
										{errors.phone_number}
									</FormHelperText>
								</FormControl>
							</Stack>

							<Stack spacing='6'>
								<TrButton
									onClick={sendVerifyRequest}
									isLoading={loading}
									isDisabled={loading}>
									Verify
								</TrButton>
							</Stack>
						</Stack>
					</Box>
				</Stack>
			) : (
				<ChangePasswordForm code={pin} />
			)}
		</Container>
	);
};

export default VerifyCode;
