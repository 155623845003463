import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const BackgroundText: React.FC = () => {
	return (
		<Box textAlign='center'>
			<Text
				display={{ base: 'none', lg: 'block' }}
				fontFamily='Poppins'
				fontSize={{ 'base': '41px', '2xl': '51px' }}
				fontStyle='normal'
				fontWeight={'bold'}
				lineHeight='63.899px'
				background='linear-gradient(90deg, rgba(255, 255, 255, 0.63) 0%, rgba(115, 119, 127, 0.63) 100%)'
				backgroundClip='text'
				color='transparent'>
				Satiate your{' '}
				<Text
					fontFamily={'Poppins'}
					fontWeight={'bold'}
					as='span'
					color='white'
					fontSize={{ 'base': '41px', '2xl': '51px' }}
					lineHeight='63.899px'>
					Wanderlust
				</Text>
				, one step at a time.
			</Text>
			<Text
				display={{ base: 'block', lg: 'none' }}
				fontFamily='Poppins'
				fontSize='35px'
				fontStyle='normal'
				fontWeight={200}
				lineHeight='63.899px'
				background='linear-gradient(90deg, rgba(255, 255, 255, 0.63) 0%, rgba(115, 119, 127, 0.63) 100%)'
				backgroundClip='text'
				color='transparent'>
				Satiate your <br />
				<Text
					fontFamily={'Poppins'}
					as='span'
					color='white'
					fontSize='35px'
					lineHeight='63.899px'>
					Wanderlust
				</Text>
				, one <br />
				step at a time.
			</Text>
		</Box>
	);
};

export default BackgroundText;
