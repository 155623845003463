import { Flex, Text, UnorderedList, ListItem } from '@chakra-ui/react';
import { pixels } from '../theme';

interface ItineraryDayProps {
	title: string;
	details: string[];
}

const ItineraryDay: React.FC<ItineraryDayProps> = ({ title, details }) => {
	return (
		<Flex flexDirection='column' mt='4'>
			<Text {...listItemTextStyle}>{title}</Text>
			{details.length > 0 ? (
				<UnorderedList mt='2' ml='4' styleType='disc'>
					{details.map((detail, index) => (
						<ListItem key={index} {...listItemTextStyle}>
							{detail}
						</ListItem>
					))}
				</UnorderedList>
			) : null}
		</Flex>
	);
};

const listItemTextStyle = {
	color: 'trTextColor',
	fontFamily: 'Roboto',
	fontSize: 'md_12',
	fontWeight: 'normal',
	lineHeight: pixels['20pixels'],
	letterSpacing: '0.25px',
};

export default ItineraryDay;
