import React from 'react';
import { Box, Button, ButtonProps, Image, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { pixels } from '../../theme';

interface SocialAuthButtonProps extends ButtonProps {
	iconUrl?: string;
	name: string;

	colorScheme: string;
}

const SocialAuthButton: React.FC<SocialAuthButtonProps> = ({
	iconUrl,
	name,
	colorScheme,
	onClick,
}) => {
	return (
		<Button
			as={Button || Link}
			colorScheme={colorScheme}
			display='flex'
			w={{ base: '100%', lg: '100%' }}
			height={{ base: '58px', lg: '58px' }}
			justifyContent='space-between'
			alignItems='center'
			gap='10px'
			borderRadius='12px'
			border='1px solid #515151'
			background={colorScheme}
			onClick={onClick}
			fontSize='16'
			fontFamily='Poppins'
			fontWeight='700'
			lineHeight='18px'>
			<Image src={iconUrl} alt={'name'} />
			<Text mr={pixels['30pixels']}>{name}</Text>
			<Box></Box>
		</Button>
	);
};

export default SocialAuthButton;
