import {
	FormControl,
	FormLabel,
	IconButton,
	FormHelperText,
	InputGroup,
	InputProps,
	InputRightElement,
	useDisclosure,
	useMergeRefs,
} from '@chakra-ui/react';
import { forwardRef, useRef } from 'react';
import { ViewIcon as HiEye, ViewOffIcon as HiEyeOff } from '@chakra-ui/icons';
import UIInput from './UIInput';

interface PasswordFieldProps extends InputProps {
	label?: string;
	errorMessage?: string;
}

export const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>(
	({ label, errorMessage, ...props }, ref) => {
		const { isOpen, onToggle } = useDisclosure();
		const inputRef = useRef<HTMLInputElement>(null);

		const mergeRef = useMergeRefs(inputRef, ref);
		const onClickReveal = () => {
			onToggle();
			if (inputRef.current) {
				inputRef.current.focus({ preventScroll: true });
			}
		};

		return (
			<FormControl>
				<FormLabel color='trTextColor' htmlFor='password'>
					{label}
				</FormLabel>
				<InputGroup>
					<InputRightElement>
						<IconButton
							top='5px'
							variant='text'
							aria-label={isOpen ? 'Mask password' : 'Reveal password'}
							icon={isOpen ? <HiEyeOff /> : <HiEye />}
							onClick={onClickReveal}
						/>
					</InputRightElement>
					<UIInput
						borderRadius={'50px'}
						id='password'
						ref={mergeRef}
						name='password'
						type={isOpen ? 'text' : 'password'}
						autoComplete='current-password'
						required
						onChange={(e) => props.onChange}
						isInvalid={!!errorMessage || props.isInvalid} // Check if errorMessage exists
						{...props}
					/>
				</InputGroup>
				{errorMessage && (
					<FormHelperText color='red'>{errorMessage}</FormHelperText>
				)}
			</FormControl>
		);
	},
);

PasswordField.displayName = 'PasswordField';
