import {
	Input,
	InputGroup,
	InputRightElement,
	IconButton,
	Box,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FiSend } from 'react-icons/fi';
import { pixels } from '../../theme';

const ChatInput: React.FC = () => {
	const [message, setMessage] = useState<string>('');

	const handleSendMessage = () => {
		if (message.trim() === '') return;
		console.log('Message sent:', message); // You can add your send logic here
		setMessage(''); // Clear the input after sending
	};

	return (
		<Box
			my={pixels['20pixels']}
			p={0}
			bg='white'
			borderRadius={'99px'}
			boxShadow='none'>
			<InputGroup
				height={'56px'}
				borderRadius={'99px'}
				boxShadow={'0px 48px 100px 0px rgba(17, 12, 46, 0.15)'}
				size='md'>
				<Input
					height={'56px'}
					border='none'
					borderRadius={'99px'}
					boxShadow={'0px 48px 100px 0px rgba(17, 12, 46, 0.15)'}
					// pr='4.5rem'
					type='text'
					placeholder='Type here...'
					value={message}
					onChange={(e) => setMessage(e.target.value)}
					bg='white'
					color='black'
				/>
				<InputRightElement height={'56px'} my='auto' width='4.5rem'>
					<IconButton
						aria-label='Send message'
						icon={<FiSend />}
						size='sm'
						isRound
						colorScheme='purple'
						onClick={handleSendMessage}
					/>
				</InputRightElement>
			</InputGroup>
		</Box>
	);
};

export default ChatInput;
