import { Flex, Image, Text } from '@chakra-ui/react';
import IteneraryTitleAndList from '../components/ItenaryTitleAndList';
import ItenaryWithDailyCost from '../components/ItenaryWithDailyCost';

interface VideoHighlight {
	PlaceName: string;
	Description: string;
	ImageUrl: string;
}

interface ItineraryItem {
	title: string;
	details: string[];
}

interface VideoHighlightsSectionProps {
	data: VideoHighlight[];
	itinerary: ItineraryItem[];
}

const TrVideoItenaryDetailBody: React.FC<VideoHighlightsSectionProps> = ({
	data,
	itinerary,
}) => {
	return (
		<Flex flexDir='column'>
			<Image src='./images/itenary-location-background.png' />
			<IteneraryTitleAndList
				title='Flights:'
				listItems={[
					'Origin: New York (JFK) to London (Heathrow, LHR)',
					'Airline: British Airways',
					'Cost: Approximately $600 - $1200 for economy, round-trip per person. Prices can fluctuate based on booking time and availability.',
				]}
			/>
			<IteneraryTitleAndList
				title='Hotel:'
				listItems={[
					'Name: The May Fair Hotel',
					'Cost: Approximately $400 - $600 per night for a standard room. Total for 4 nights: $1600 - $2400.',
				]}
			/>
			<Flex mt='4' flexDir='column'>
				<Text
					color='trTextColor'
					fontFamily='Roboto'
					fontSize='md'
					fontWeight='medium_bold'
					lineHeight='normal'
					letterSpacing='0.25px'>
					Itinerary with Estimated Daily Costs:
				</Text>
				{itinerary.map((item, index) => (
					<ItenaryWithDailyCost
						key={index}
						title={item.title}
						details={item.details}
					/>
				))}
			</Flex>
		</Flex>
	);
};

export default TrVideoItenaryDetailBody;
