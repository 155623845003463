import { useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	Container,
	Divider,
	Heading,
	HStack,
	Link,
	Stack,
	Text,
	FormControl,
	FormLabel,
	FormHelperText,
	Alert,
	AlertIcon,
	useToast,
} from '@chakra-ui/react';
import { PasswordField } from '../components/TrPasswordField';
import { OAuthButtonGroup } from '../components/OAuthButtonGroup';
import { pixels } from '../theme';
import TrButton from '../components/TrButton';
import TraverseLogo from '../components/TraverseLogo';
import useAxios from '../hooks/useAxios';
import PhoneNumberInput from '../components/PhoneNumberInput';
import UIInput from '../components/UIInput';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
const validateFields = (values: any) => {
	const errors: any = {};

	if (!values.phone_number) {
		errors.phone_number = 'Phone number is required';
	} else if (!isValidPhoneNumber(values.phone_number)) {
		errors.phone_number = 'Invalid phone number';
	}

	if (!values.first_name) {
		errors.first_name = 'First name is required';
	}

	if (!values.last_name) {
		errors.last_name = 'Last name is required';
	}

	if (!values.password) {
		errors.password = 'Password is required';
	}

	return errors;
};

const Signup: React.FC = () => {
	const toast = useToast();
	const navigate = useNavigate();
	const { loading, fetchData } = useAxios();
	const {
		data: data2,
		error: error2,
		loading: loading2,
		fetchData: fetchData2,
	} = useAxios();
	const initRegisterFields = {
		first_name: '',
		last_name: '',
		phone_number: '',
		password: '',
	};
	const [registerFields, setRegisterFields] = useState(initRegisterFields);
	const [errors, setErrors] = useState<any>({});
	const [serverError, setServerError] = useState<string>('');
	const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name: string = event.target.name;
		const value: string = event.target.value;
		setErrors({
			...errors,
			[name]: '',
		});
		setRegisterFields((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const setPhoneValue = (value: any) => {
		setErrors({
			...errors,
			phone_number: '',
		});
		setRegisterFields({
			...registerFields,
			phone_number: value,
		});
	};

	const register = async () => {
		const formErrors = validateFields(registerFields);

		if (Object.keys(formErrors).length === 0) {
			const { error, statusCode, data } = await fetchData({
				method: 'POST',
				url: 'register',
				options: {
					data: registerFields,
					headers: { 'Content-Type': 'application/json' },
				},
				auth: false,
			});
			if (statusCode === 201) {
				toast({
					title: 'Account created.',
					description: "We've created your account for you.",
					status: 'success',
					duration: 9000,
					isClosable: true,
					position: 'top-right',
				});
				console.log('data token', data?.token);
				localStorage.setItem('token', data?.token);
				setErrors(initRegisterFields);
				navigate(`/verify`, {
					state: {
						id: registerFields.phone_number,
						type: 'phone_number_verification',
					},
				});
				setRegisterFields(initRegisterFields);
			} else {
				setServerError(JSON.stringify(error));
			}
		} else {
			setErrors(formErrors);
		}
	};

	const socialAuth = async () => {
		await fetchData2({
			method: 'GET',
			url: 'authorize/facebook',
			options: {
				headers: { 'Content-Type': 'application/json' },
			},
			auth: false,
		});
		console.log('data2', data2);
		console.log('error2', error2);
	};

	return (
		<Container
			color='trTextColor'
			background={'#fcfdfe'}
			h='100vh'
			maxW='lg'
			py={{ base: '12', md: '24' }}
			px={{ base: '0', sm: '8' }}>
			<Stack spacing='8'>
				<Stack spacing='6'>
					<Stack spacing={{ base: '2', md: '3' }} textAlign='center'>
						<Box mb={pixels['20pixels']}>
							<TraverseLogo />
						</Box>
						<Heading fontSize={{ base: pixels['30pixels'] }}>
							Register with us
						</Heading>

						<Text color='fg.muted'>
							Do you already have an account? <Link href='signin'>Sign in</Link>
						</Text>
					</Stack>
				</Stack>
				{serverError ? (
					<Alert status='error'>
						<AlertIcon />
						{serverError || serverError !== null
							? serverError
							: 'There was an error processing your request'}
					</Alert>
				) : (
					''
				)}
				<Box
					backgroundColor={'trWhiteColor'}
					py={{ base: '0', sm: '8' }}
					px={{ base: '4', sm: '10' }}
					bg={{ base: 'transparent', sm: 'bg.surface' }}
					boxShadow={{ base: 'none', sm: 'md' }}
					borderRadius={{ base: 'none', sm: 'xl' }}>
					<Stack spacing='6'>
						<Stack spacing='5'>
							<FormControl>
								<Text pb='8px'>
									Phone Number: {registerFields.phone_number}
								</Text>
								<PhoneNumberInput
									isInvalid={errors.phone_number}
									isDisable={loading || loading2}
									value={registerFields.phone_number}
									onChange={(value) => setPhoneValue(value)}
								/>
								<FormHelperText color={'red'}>
									{errors.phone_number}
								</FormHelperText>
							</FormControl>
							<FormControl>
								<FormLabel color='trTextColor' htmlFor='first_name'>
									First Name
								</FormLabel>
								<UIInput
									isInvalid={errors.first_name}
									isDisable={loading || loading2}
									borderRadius={'50px'}
									onChange={handleFormChange}
									name='first_name'
									id='first_name'
									type='text'
								/>
								<FormHelperText color={'red'}>
									{errors.first_name}
								</FormHelperText>
							</FormControl>

							<FormControl>
								<FormLabel color='trTextColor' htmlFor='last_name'>
									Last Name
								</FormLabel>
								<UIInput
									isInvalid={errors.last_name}
									isDisable={loading || loading2}
									borderRadius={'50px'}
									onChange={handleFormChange}
									name='last_name'
									id='last_name'
									type='text'
								/>
								<FormHelperText color={'red'}>
									{errors.last_name}
								</FormHelperText>
							</FormControl>

							<PasswordField
								label='Password'
								errorMessage={errors.password}
								isInvalid={errors.password}
								isDisabled={true || loading2}
								name='password'
								onChange={handleFormChange}
							/>
						</Stack>
						<HStack justify='space-between'>
							<Checkbox defaultChecked>Remember me</Checkbox>
							<Button variant='text' size='sm'>
								Forgot password?
							</Button>
						</HStack>
						<Stack spacing='6'>
							<TrButton
								onClick={register}
								isLoading={loading}
								isDisabled={loading2}>
								Sign in
							</TrButton>
							<HStack>
								<Divider />
								<Text textStyle='sm' whiteSpace='nowrap' color='fg.muted'>
									or continue with
								</Text>
								<Divider />
							</HStack>
							<OAuthButtonGroup
								isDisabled={loading}
								onClick={socialAuth}
								isLoading={loading2}
							/>
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</Container>
	);
};

export default Signup;
