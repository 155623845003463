import { ButtonGroup, IconButton, VisuallyHidden } from '@chakra-ui/react';
import { FacebookIcon } from './ProviderIcons';

interface Provider {
	name: string;
	icon: JSX.Element;
}

const providers: Provider[] = [{ name: 'facebook', icon: <FacebookIcon /> }];

interface OAuthButtonGroupProps {
	isLoading?: boolean;

	onClick?: () => void;

	isDisabled?: boolean;
}

export const OAuthButtonGroup: React.FC<OAuthButtonGroupProps> = ({
	isLoading,
	onClick,
	isDisabled,
}) => (
	<ButtonGroup variant='secondary' spacing='4'>
		{providers.map(({ name, icon }) => (
			<IconButton
				disabled={isDisabled}
				onClick={onClick}
				borderRadius={'50px'}
				icon={icon}
				border='1px solid'
				aria-label={`Sign in with ${name}`}
				key={name}
				flexGrow={1}
				isDisabled={isDisabled}
				isLoading={isLoading}>
				<VisuallyHidden>Sign in with {name}</VisuallyHidden>
				{icon}
			</IconButton>
		))}
	</ButtonGroup>
);
