import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
// import { pixels } from '../theme';
export enum MapTypeId {
	/**
	 * This map type displays a transparent layer of major streets on satellite
	 * images.
	 */
	HYBRID = 'hybrid',
	/**
	 * This map type displays a normal street map.
	 */
	ROADMAP = 'roadmap',
	/**
	 * This map type displays satellite images.
	 */
	SATELLITE = 'satellite',
	/**
	 * This map type displays maps with physical features such as terrain and
	 * vegetation.
	 */
	TERRAIN = 'terrain',
}

interface MapComponentProps {
	// 	latitude: number;
	// 	longitude: number;

	mapType?: MapTypeId;

	places?: [any];
}

const MapComponent: React.FC<MapComponentProps> = ({
	// latitude,
	// longitude,
	places,
	mapType = 'roadmap',
}) => {
	// const position = { lat: latitude, lng: longitude };
	const apiKey: string = process.env.REACT_APP_GOOGLE_API_KEY || '';
	//@ts-ignore
	console.log('places type', places);
	const component = (
		// places && places.length > 0 ? (
		<APIProvider apiKey={apiKey}>
			<Map
				zoomControl={false}
				mapTypeId={mapType}
				rotateControl={false}
				zoomControlOptions={{ position: 1.0 }}
				fullscreenControlOptions={{ position: 3.0 }}
				scaleControl={false}
				//@ts-ignore
				defaultCenter={places[0]}
				defaultZoom={10}
				style={{
					height: '100%',
					width: '100%',
					overflow: 'hidden',
				}}>
				{places?.map((place: any) => {
					return (
						<Marker
							key={place.lng}
							position={{ lat: place?.lat, lng: place?.lng }}
						/>
					);
				})}
			</Map>
		</APIProvider>
	);
	// ) : (
	// 	<></>
	// );
	return component;
};

export default MapComponent;
