import { VStack, HStack, Text, Container, Button } from "@chakra-ui/react";

const HomePageFooterFlightCard = ({travelInfo}) => {

  const handleTravelBooking = ()=>{
    window.open(travelInfo['bookingUrl'], "_blank");
  }

  return (
    <VStack width={"100%"} pt={"10px"} borderRadius={"20px"} bg={"#111012"}>
      <Container p={"15px"}>
        <HStack fontWeight={"bold"} fontSize={"large"} w={"100%"}>
          <img
            src="/icons/plane.svg"
            alt="SVG Button"
            width="50"
            height="50"
          />
          <Text>{travelInfo['duration']}</Text>
        </HStack>
        <Text color={"#aaa7a7"} fontSize="xs">
          from {travelInfo['from']}
        </Text>
        <HStack mt={"10px"} justifyContent={"space-between"}>
          <HStack>
            <Text color={"#aaa7a7"} fontSize={"small"}>
              Prices Start from
            </Text>
            <Text color={"white"} fontWeight={"bold"}>
            {travelInfo['price']}
            </Text>
          </HStack>
          <Button
            color={"#AE7CFF"}
            bg={"#111012"}
            fontWeight={"bold"}
            fontSize={"medium"}
            onClick={handleTravelBooking}
          >
            Book
          </Button>
        </HStack>
      </Container>
    </VStack>
  );
};

export default HomePageFooterFlightCard;
