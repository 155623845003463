import { useState } from 'react';
import {
	Box,
	Container,
	Heading,
	Stack,
	Text,
	FormControl,
	FormHelperText,
	Alert,
	AlertIcon,
	useToast,
} from '@chakra-ui/react';
import { pixels } from '../theme';
import TrButton from '../components/TrButton';
import TraverseLogo from '../components/TraverseLogo';
import useAxios from '../hooks/useAxios';
import PhoneNumberInput from '../components/PhoneNumberInput';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
const validateFields = (values: any) => {
	const errors: any = {};

	if (!values.phone_number) {
		errors.phone_number = 'Phone number is required';
	} else if (!isValidPhoneNumber(values.phone_number)) {
		errors.phone_number = 'Invalid phone number';
	}

	return errors;
};

const ForgotPasswordPage: React.FC = () => {
	const toast = useToast();
	const navigate = useNavigate();
	const { loading, fetchData } = useAxios();
	const initLoginFields = {
		phone_number: '',
	};
	const [loginFields, setLoginFields] = useState(initLoginFields);
	const [errors, setErrors] = useState<any>({});
	const [serverError, setServerError] = useState<string>('');

	const setPhoneValue = (value: any) => {
		setErrors({});
		setLoginFields({
			phone_number: value,
		});
	};

	const requestPasswordReset = async () => {
		setErrors({});
		const formErrors = validateFields(loginFields);
		console.log(Object.keys(formErrors).length);
		if (Object.keys(formErrors).length === 0) {
			const { error, statusCode, data } = await fetchData({
				method: 'POST',
				url: '/generate_code/password',
				options: {
					data: loginFields,
					headers: { 'Content-Type': 'application/json' },
				},
				auth: false,
			});
			if (statusCode === 200) {
				toast({
					title: 'Password reset code sent.',
					description: '',
					status: 'success',
					duration: 9000,
					isClosable: true,
					position: 'top-right',
				});
				console.log('data token', data?.token);
				localStorage.setItem('token', data?.token);
				setErrors(initLoginFields);
				navigate(`/verify`, {
					state: {
						id: loginFields.phone_number,
						type: 'password_reset_request',
					},
				});
				setLoginFields(initLoginFields);
			} else {
				setServerError(JSON.stringify(error));
			}
		} else {
			setErrors(formErrors);
		}
	};

	return (
		<Container
			color='trTextColor'
			background={'#fcfdfe'}
			h='100vh'
			maxW='lg'
			py={{ base: '12', md: '24' }}
			px={{ base: '0', sm: '8' }}>
			<Stack spacing='8'>
				<Stack spacing='6'>
					<Stack spacing={{ base: '2', md: '3' }} textAlign='center'>
						<Box mb={pixels['20pixels']}>
							<TraverseLogo />
						</Box>
						<Heading fontSize={{ base: pixels['30pixels'] }}>
							Request Password Change
						</Heading>
					</Stack>
				</Stack>
				{serverError ? (
					<Alert status='error'>
						<AlertIcon />
						{serverError || serverError !== null
							? serverError
							: 'There was an error processing your request'}
					</Alert>
				) : (
					''
				)}
				<Box
					backgroundColor={'trWhiteColor'}
					py={{ base: '0', sm: '8' }}
					px={{ base: '4', sm: '10' }}
					bg={{ base: 'transparent', sm: 'bg.surface' }}
					boxShadow={{ base: 'none', sm: 'md' }}
					borderRadius={{ base: 'none', sm: 'xl' }}>
					<Stack spacing='6'>
						<Stack spacing='5'>
							<FormControl>
								<Text pb='8px'>Phone Number: {loginFields.phone_number}</Text>
								<PhoneNumberInput
									isInvalid={errors.phone_number}
									isDisable={loading}
									value={loginFields.phone_number}
									onChange={(value) => setPhoneValue(value)}
								/>
								<FormHelperText color={'red'}>
									{errors.phone_number}
								</FormHelperText>
							</FormControl>
						</Stack>

						<Stack spacing='6'>
							<TrButton
								onClick={requestPasswordReset}
								isLoading={loading}
								isDisabled={loading}>
								Send Passord Change Request
							</TrButton>
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</Container>
	);
};

export default ForgotPasswordPage;
