import { extendTheme } from '@chakra-ui/react';
import '@fontsource/roboto';
import '@fontsource/plus-jakarta-sans';
import '@fontsource/source-sans-pro';
import '@fontsource/poppins';
import type { StyleFunctionProps } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

const sizes = {
	lg_40: '40px',
	lg_16: '16px',
	lg_20: '20px',
	lg_46: '46px',
};

const height = {
	...sizes,
};
export const colors = {
	primary: '#9B5CFF',
	secondary: '#515151',
	trSecondary: '#515151',
	trInstagramBlack: '#000000FC',
	trFacebookBlue: '#316FF6',
	trBlack: '#161517',
	trPrimary: '#9B5CFF',
	trWhiteColor: '#fff',
	trBlackColor: '#000',
	trPrimaryColor: '#BE6517',
	trBackgroundColor: '#F0F0F0',
	trBgColor: '#fcfdfe',
	trTextColor: '#284B63',
	trButtonColor: '#EB9533',
	trGrey: 'rgba(22, 21, 23, 0.13)',
	trGreyFont: 'rgba(22, 21, 23, 0.33)',
	trGreyFont63: 'rgba(22, 21, 23, 0.63)',
	trBlack01: '#151416',
};

const fontWeights = {
	normal: 400, // Default font weight
	medium: 500,
	medium_bold: 600,
	bold: 700,
	bold_900: 900,
	// Add more font weights as needed
};

const lineHeights = {
	'lineheight-20': '20px',
	'lineheight-24': '24px',
};

const fontSizes = {
	'sm': '10px',
	'md_12': '12px',
	'md': '14px',
	'lg': '16px',
	'lg_18': '18px',
	'lg_20': '20px',
	'2xl': '28px',
	'xl_30': '30px',

	'xxl_54': '54px',
};

const styles = {
	global: (props: StyleFunctionProps) => ({
		body: {
			background: '#fff',
			overflowX: 'hidden',
			maxWidth: '100vw',
			fontFamily: 'Poppins',
			color: mode('gray.800', 'whiteAlpha.900')(props),
			bg: mode('white', 'gray.800')(props),
			lineHeight: 'base',
		},
	}),
};

export const pixels = {
	'5pixels': '5px',
	'10pixels': '10px',
	'11pixels': '11px',
	'12pixels': '12px',
	'13pixels': '13px',
	'14pixels': '14px',
	'16pixels': '16px',
	'18pixels': '18px',
	'20pixels': '20px',
	'24pixels': '24px',
	'27pixels': '27px',
	'28pixels': '28px',
	'30pixels': '30px',
	'34pixels': '34px',
	'40pixels': '40px',
	'50pixels': '50px',
	'55pixels': '55px',
	'65pixels': '65px',
	'100pixels': '100px',
	'113pixels': '113px',
	'150pixels': '150px',
	'155pixels': '155px',
	'200pixels': '200px',
	'140pixels': '140px',
	'246pixels': '246px',
	'700pixels': '700px',
	'800pixels': '800px',
	'894pixels': '894px',
	'1100pixels': '1100px',
};

const textStyles = {
	cardHeader: {
		my: 'auto',
		color: 'rgba(255, 255, 255, 0.93)',
		fontFamily: 'Poppins',
		fontSize: '32px',
		fontWeight: '800',
		lineHeight: '18px',
	},
	cardSubHeader: {
		color: '#AA78FB',
		fontFamily: 'Poppins',
		fontSize: '16px',
		fontWeight: '600',
		lineHeight: '18px', // Adjust if needed for better spacing
		textDecoration: 'underline',
	},
	cardDescription: {
		color: 'rgba(255, 255, 255, 0.93)',
		fontFamily: 'Poppins',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '23px',
		// overflow: 'hidden',
		// textOverflow: 'ellipsis',
	},
};

const breakpoints = {
	'sm': '30em', // 480px
	'md': '48em', // 768px
	'lg': '62em', // 992px
	'xl': '80em', // 1280px
	'2xl': '96em', // 1536px
};

export const theme = extendTheme({
	styles,
	colors,
	fontSizes,
	fontWeights,
	lineHeights,
	height,
	sizes,
	colorSchemes: colors,
	textStyles: textStyles,
	breakpoints: breakpoints,
});
