import { ButtonProps, Button as ChakraButton } from '@chakra-ui/react';

const style = {
	display: 'flex',
	minWidth: '100px',
	height: '46px',
	borderRadius: '100px',
	// border: '1px solid rgba(53, 54, 53, 0.20)',
	justifyContent: 'center',
	alignItems: 'center',
	background: 'transparent',
	boxShadow: '0',
	color: '#fff',
};
const TrTransparentButton = ({
	onClick,
	colorScheme,
	size,
	isDisabled,
	children,
	...rest
}: ButtonProps) => {
	return (
		<ChakraButton
			_hover={{
				opacity: 0.9,
			}}
			_active={{
				opacity: 0.7,
			}}
			{...style}
			display={'flex'}
			onClick={onClick}
			colorScheme={colorScheme}
			size={size}
			isDisabled={isDisabled}
			{...rest}>
			{children}
		</ChakraButton>
	);
};

export default TrTransparentButton;
