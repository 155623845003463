import { Text, BoxProps } from '@chakra-ui/react';
import useTextEllipsis from '../hooks/useTextElipsis';
import { pixels } from '../theme';

interface LocationDescriptionTextProps {
	text: string;
	sx?: BoxProps['sx']; // Define 'sx' prop type for custom styles
}

const LocationDescriptionText = (props: LocationDescriptionTextProps) => {
	const { sx, text } = props;
	const { truncatedText, isExpanded, toggleExpanded, valid } = useTextEllipsis(
		text,
		{
			wordsLimit: 15,
		},
	);

	return (
		<Text
			overflow='hidden'
			color='#284B63'
			// textOverflow='ellipsis'
			// whiteSpace='nowrap'
			fontFamily='Roboto'
			fontSize='md_12'
			fontStyle='normal'
			fontWeight={'normal'}
			lineHeight={pixels['18pixels']}
			letterSpacing='0.25px'
			sx={sx} // Pass 'sx' prop for custom styles
		>
			{truncatedText}{' '}
			{valid && (
				<button onClick={toggleExpanded}>
					{!isExpanded ? 'more' : '(show less)'}
				</button>
			)}
		</Text>
	);
};

export default LocationDescriptionText;
