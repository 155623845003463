import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { Box, Flex,  } from "@chakra-ui/react";
import MoreFromInfluecerCard from "./MoreFromInfluecerCard";

const SavedVideos = () => {
    const context = useContext(AppContext);
    const { likeSearches, bucketListCard } = context;
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        const tempSavedVideos = [];
        for (let search of likeSearches) {
            if (
                search["country"] === bucketListCard[0] &&
                search["city"] === bucketListCard[1]
            ) {
                tempSavedVideos.push(search);
            }
        }
        setVideos(tempSavedVideos);
    }, [likeSearches , bucketListCard]);

    return (
        <Box w={"100%"} pt={'20px'}>
            <Flex w={"100%"} minH={"100vh"} wrap="wrap" justifyContent="flex-start">
                {videos.map((video, index) => {
                    return (
                        <MoreFromInfluecerCard
                            key={index}
                            placeName={video["name"]}
                            imageUrl={video["previewImage"]}
                            videoUrl={video["url"]}
                            boxSize={"330px"}
                            width={"260px"}
                        />
                    );
                })}
            </Flex>
        </Box>
    );
};

export default SavedVideos;
