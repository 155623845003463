import React from 'react';
import {
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Image,
	Text,
} from '@chakra-ui/react';
import TrCircleButton from './TrCircleButton';

interface DrawerProps {
	isOpen: boolean;
	onClose: () => void;
	header: React.ReactNode;
	body: React.ReactNode;
	footer: React.ReactNode;

	size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
}

const TrDrawer: React.FC<DrawerProps> = ({
	isOpen,
	onClose,
	body,
	footer,
	size,
}) => {
	return (
		<Drawer size={size} isOpen={isOpen} placement='right' onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader display='flex' flexDirection={'row'} gap='16px'>
					<TrCircleButton
						onClick={onClose}
						sx={{
							background: 'transparent',
							boxShadow: 'unset',
							border: 'none',
						}}
						aria-label=''>
						<Image src='./icons/chevron-left.svg' />
					</TrCircleButton>
					<Text
						sx={{
							my: 'auto',
							color: '#21383E',
							fontFamily: 'Roboto',
							fontSize: '16px',
							fontStyle: 'normal',
							fontWeight: 'lg',
							lineHeight: 'normal',
							letterSpacing: '0.25px',
						}}>
						Itinerary
					</Text>
				</DrawerHeader>

				<DrawerBody>{body}</DrawerBody>

				<DrawerFooter p={0} display={'flex'} justifyContent={'center'}>
					{footer}
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
};

export default TrDrawer;
