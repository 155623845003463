import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';
import { pixels } from '../../theme';

const TrTealButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
	return (
		<Button
			fontSize={pixels['20pixels']}
			colorScheme='teal'
			height='48px'
			borderRadius='8px'
			border='1px solid #38B2AC'
			background='linear-gradient(180deg, #81E6D9 0%, #319795 100%)'
			color='white'
			_hover={{ bg: 'linear-gradient(180deg, #4FD1C5 0%, #2C7A7B 100%)' }}
			_active={{ bg: 'linear-gradient(180deg, #285E61 0%, #234E52 100%)' }}
			{...rest}>
			{children}
		</Button>
	);
};

export default TrTealButton;
