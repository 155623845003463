import { Flex, Heading, useDisclosure } from '@chakra-ui/react';
import TrVideoHighlightCard from './TrVideoHighlightCard';
import TrDrawer from '../components/TrDrawer';
import ItenaryDetailFooter from '../components/ItenaryDetailFooter';
import TrVideoItenaryDetailBody from '../components/TrVideoItenaryDetailBody';

const VideoHighlightsSection = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const data = [
		{
			PlaceName: 'Tower of London',
			Description: `London is one of the most iconic cities in the world, renowned for its
      rich history, vibrant culture, and world-class attractions. rich
      history, vibrant culture, and world-class attractions.`,
			ImageUrl:
				'https://t4.ftcdn.net/jpg/02/57/75/51/360_F_257755130_JgTlcqTFxabsIKgIYLAhOFEFYmNgwyJ6.jpg',
		},
		{
			PlaceName: 'Tower of London',
			Description: `London is one of the most iconic cities in the world, renowned for its
      rich history, vibrant culture, and world-class attractions. rich
      history, vibrant culture, and world-class attractions.`,
			ImageUrl:
				'https://t4.ftcdn.net/jpg/02/57/75/51/360_F_257755130_JgTlcqTFxabsIKgIYLAhOFEFYmNgwyJ6.jpg',
		},
		{
			PlaceName: 'Tower of London',
			Description: `London is one of the most iconic cities in the world, renowned for its
      rich history, vibrant culture, and world-class attractions. rich
      history, vibrant culture, and world-class attractions.`,
			ImageUrl:
				'https://t4.ftcdn.net/jpg/02/57/75/51/360_F_257755130_JgTlcqTFxabsIKgIYLAhOFEFYmNgwyJ6.jpg',
		},
		{
			PlaceName: 'Tower of London',
			Description: `London is one of the most iconic cities in the world, renowned for its
      rich history, vibrant culture, and world-class attractions. rich
      history, vibrant culture, and world-class attractions.`,
			ImageUrl:
				'https://t4.ftcdn.net/jpg/02/57/75/51/360_F_257755130_JgTlcqTFxabsIKgIYLAhOFEFYmNgwyJ6.jpg',
		},
		{
			PlaceName: 'Tower of London',
			Description: `London is one of the most iconic cities in the world, renowned for its
      rich history, vibrant culture, and world-class attractions. rich
      history, vibrant culture, and world-class attractions.`,
			ImageUrl:
				'https://t4.ftcdn.net/jpg/02/57/75/51/360_F_257755130_JgTlcqTFxabsIKgIYLAhOFEFYmNgwyJ6.jpg',
		},
	];

	const dataF = [
		{
			PlaceName: 'Tower of London',
			Description: `London is one of the most iconic cities in the world, renowned for its rich history, vibrant culture, and world-class attractions.`,
			ImageUrl:
				'https://t4.ftcdn.net/jpg/02/57/75/51/360_F_257755130_JgTlcqTFxabsIKgIYLAhOFEFYmNgwyJ6.jpg',
		},
		{
			PlaceName: 'Westminster Abbey',
			Description: `Westminster Abbey is a large, mainly Gothic abbey church in the City of Westminster, London, England, just to the west of the Palace of Westminster. It is one of the United Kingdom's most notable religious buildings and the traditional place of coronation and burial site for English and, later, British monarchs.`,
			ImageUrl:
				'https://t3.ftcdn.net/jpg/02/99/21/07/360_F_299210725_SVGBBteVSsjGDhA7kZoQquWmTSS4kDHb.jpg',
		},
		{
			PlaceName: 'British Museum',
			Description: `The British Museum, in the Bloomsbury area of London, England, is a public institution dedicated to human history, art and culture. Its permanent collection of some eight million works is among the largest and most comprehensive in existence, having been widely collected during the era of the British Empire. It documents the story of human culture from its beginnings to the present. It was the first public national museum in the world.`,
			ImageUrl:
				'https://t3.ftcdn.net/jpg/02/91/43/55/360_F_291435571_MuKH2iNWz1Y3cHZ6iyX8xJvd7Tas9dJP.jpg',
		},
		{
			PlaceName: 'West End Theatre District',
			Description: `The West End of London (commonly referred to as the West End) is a district of Central London, in the United Kingdom, and a part of the wider West End area. It has a concentration of theatres, music halls, and entertainment venues, including the major West End theatres. West End theatre is generally seen as the highest level of commercial theatre in the English-speaking world.`,
			ImageUrl:
				'https://t4.ftcdn.net/jpg/03/43/07/44/360_F_343074451_p0E9mE7xd4SfYpbLbiWmL8G6aX7NTr2x.jpg',
		},
	];

	const itinerary = [
		{
			title: 'Day 1: Arrival and Westminster',
			details: [
				'Activities: No major costs aside from meals and transport (Oyster card for public transport recommended, around $40 for a 5-day pass).',
				'Dining: $50 - $100 per person for pub dinner.',
			],
		},
		{
			title: 'Day 2: Museum Day and West End Show',
			details: [
				'Museums: Free (donations welcome).',
				'West End Show: $75 - $150 per ticket.',
				'Dining: $100 - $200 per person (including a nicer dinner in Soho).',
			],
		},
		{
			title: 'Day 3: Tower of London and City Exploration',
			details: [
				'Tower of London Entry: $35 per person.',
				'Dinner in The Shard: $150 - $300 per person.',
				'Other: Transport and lunch costs, approximately $50 - $100.',
			],
		},
		{
			title: 'Day 4: Day Trip to Windsor',
			details: [
				'Train to Windsor: $30 round-trip per person.',
				'Windsor Castle Entry: $30 per person.',
				'Dining and Miscellaneous: $100 - $200 per person.',
			],
		},
		{
			title: 'Day 5: Shopping and Departure',
			details: [
				'Shopping: Variable, depending on personal budget.',
				'Transport to Airport: $30 with the Heathrow Express from Paddington Station.',
			],
		},
	];

	return (
		<Flex maxWidth={'md'} direction={'column'} p={'10px 15px'}>
			<TrDrawer
				size={'full'}
				isOpen={isOpen}
				onClose={onClose}
				onOpen={onOpen}
				footer={<ItenaryDetailFooter />}
				body={<TrVideoItenaryDetailBody data={dataF} itinerary={itinerary} />}
			/>
			<Heading
				as={'h2'}
				fontSize={'lg'}
				color={'black'}
				bg={'white'}
				pl={'5px'}>
				Video Highlights
			</Heading>

			{data.map((value, index) => {
				return (
					<TrVideoHighlightCard
						key={index}
						placename={value['PlaceName']}
						description={value['Description']}
						imageUrl={value['ImageUrl']}
						openDrawer={onOpen}
					/>
				);
			})}
		</Flex>
	);
};

export default VideoHighlightsSection;
