import React from 'react';
import LocationBox from './LocationBox';
import { Box, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import data from './data.json'; // Import the JSON data

const ListItinerary: React.FC = () => {
	const navigation = useNavigate();

	// Extract the itineraries array from the imported data
	const itineraries = data.destinations.map((dest) => ({
		id: dest.id,
		destination: dest.destinationName,
		dates: dest.dates,
		numberOfAdults: dest.noOfAdults,
	}));

	const handleEdit = (id: number) => {
		navigation(`/home/itineraries/${id}`);
		console.log(`Edit button clicked for itinerary with id: ${id}`);
	};

	return (
		<Box
			bg={'linear-gradient(118.03deg, #272529 5.68%, #111012 59.38%)'}
			minH='100vh'
			w='100vw'
			position='relative'
			overflow='hidden'>
			<VStack mt={['0', '70px', '70px']} spacing={'20px'} w={"-moz-fit-content"}>
				{itineraries.map((itinerary) => (
					<LocationBox
						key={itinerary.id}
						destination={itinerary.destination}
						dates={itinerary.dates}
						numberOfAdults={itinerary.numberOfAdults}
						onEdit={() => handleEdit(itinerary.id)}
					/>
				))}
			</VStack>
		</Box>
	);
};

export default ListItinerary;
