import {
	Flex,
	ListItem,
	Text,
	TextProps,
	UnorderedList,
} from '@chakra-ui/react';
import { pixels } from '../theme';

interface ItineraryTitleAndListProps {
	title: string;
	listItems: string[];
}

const ItineraryTitleAndList: React.FC<ItineraryTitleAndListProps> = ({
	title,
	listItems,
}) => {
	return (
		<Flex flexDirection='column'>
			<Text {...titleTextStyle}>{title}</Text>

			<UnorderedList>
				{listItems.map((item, index) => (
					<ListItem key={index}>
						<Text {...listItemTextStyle}>{item}</Text>
					</ListItem>
				))}
			</UnorderedList>
		</Flex>
	);
};

const titleTextStyle: TextProps = {
	color: 'trTextColor',
	fontFamily: 'Roboto',
	fontSize: 'md',
	fontStyle: 'normal',
	fontWeight: 'medium_bold',
	lineHeight: 'normal',
	letterSpacing: '0.25px',
	my: '16px',
};

const listItemTextStyle: TextProps = {
	color: 'trTextColor',
	fontFamily: 'Roboto',
	fontSize: 'md_12',
	fontStyle: 'normal',
	fontWeight: 'normal',
	lineHeight: pixels['20pixels'],
	letterSpacing: '0.25px',
};
export default ItineraryTitleAndList;
