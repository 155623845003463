import {
	Box,
	Select,
	RadioGroup,
	Stack,
	Radio,
	Input,
	Text,
	BoxProps,
	InputGroup,
	InputLeftAddon,
	useToast,
	SimpleGrid,
} from '@chakra-ui/react';
import {
	MutableRefObject,
	ReactNode,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { pixels } from '../../theme';
import TrBlackButton from '../Buttons/TrBlackButton';
import useAxios from '../../hooks/useAxios';
import ChatInput from './ChatInput';
import ItineraryCardSlice from './ItineraryCardSlice';

interface CreateItenaryProps {
	cities?: Array<string>;
	drawerBodyRef: MutableRefObject<HTMLDivElement | null>;
}

interface InfoBoxProps extends BoxProps {
	text?: string;
	children?: ReactNode;
}

interface TravelTaglineProps {
	text: string;
}

interface ItineraryGridProps {
	cards: Array<any>;
}

// Function to generate random positions
const generateCardStyles = (index: number) => {
	const xOffset = index * 50 - 10 * index; // Horizontal offset
	const yOffset = 0; // Vertical offset

	return {
		transform: `translate(${xOffset}px, ${yOffset}px)`,
		zIndex: index, // Ensure the last card is on top
	};
};

const ItineraryGrid: React.FC<ItineraryGridProps> = ({ cards }) => {
	const cardStyle = useMemo(() => {
		return cards.map((_, index) => generateCardStyles(index));
	}, [cards]);
	cards.length = 3;
	return (
		<SimpleGrid
			width={'100%'}
			height={'170px'}
			spacing={4}
			templateColumns='repeat(auto-fill, minmax(200px, 1fr))'
			position='relative'>
			{cards.map((activity, index) => (
				<Box key={index} style={cardStyle[index]} position='absolute'>
					<ItineraryCardSlice
						//@ts-ignore
						activity={activity}
					/>
				</Box>
			))}
		</SimpleGrid>
	);
};

const InfoBox: React.FC<InfoBoxProps> = ({ text, children, ...boxProps }) => {
	return (
		<Box
			bg='rgba(232, 233, 255, 1)'
			w={{ base: '100%' }}
			minH='71px'
			p='10px'
			borderRadius='8px'
			display='flex'
			justifyContent='space-between'
			{...boxProps}>
			{text && (
				<Text
					color='rgba(22, 24, 27, 1)'
					lineHeight={{ base: '17px' }}
					fontSize={{ base: '14px' }}
					fontWeight={{ base: '600' }}>
					{text}
				</Text>
			)}
			{children && <Box w='100%'>{children}</Box>}
		</Box>
	);
};

const TravelTagline: React.FC<TravelTaglineProps> = ({ text }) => {
	return (
		<Text
			color='rgba(22, 24, 27, 1)'
			lineHeight={{ base: '17px' }}
			fontSize={{ base: '14px' }}
			fontWeight={{ base: '600' }}>
			{text}
		</Text>
	);
};

const CreateItenary: React.FC<CreateItenaryProps> = ({
	cities,
	drawerBodyRef,
}) => {
	const toast = useToast();
	const { fetchData, loading, error } = useAxios();
	const [numberOfPeople, setNumberOfPeople] = useState<string>('');
	const [tripDuration, setTripDuration] = useState<string>('');
	const [travelType, setTravelType] = useState<string>('');
	const [startDate, setStartDate] = useState<string>('');
	const [budget, setBudget] = useState<string>('');
	const [activities, setActivities] = useState<any[]>([]);
	const [showInteractiveMessage, setShowInteractiveMessage] =
		useState<boolean>(false);
	// Scroll to bottom of the modal content when activities are updated
	useEffect(() => {
		if (activities.length > 0 && drawerBodyRef.current) {
			drawerBodyRef.current.scrollTo({
				top: drawerBodyRef.current.scrollHeight,
				behavior: 'smooth',
			});
		}
	}, [activities, drawerBodyRef]);
	const handleSubmit = async () => {
		if (!numberOfPeople || !tripDuration || !budget) {
			toast({
				title: 'All fields are required.',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
			return;
		}

		const endDate = new Date(startDate);
		endDate.setDate(endDate.getDate() + Number(tripDuration));

		const formData = {
			date_from: startDate ? startDate.split('T')[0] : undefined,
			date_to: startDate ? endDate.toISOString().split('T')[0] : undefined,
			number_of_travelers: numberOfPeople,
			type_of_trip: travelType,
			budget: { currency: 'INR', value: budget },
			favorite_destinations: cities,
		};

		const { statusCode, data } = await fetchData({
			method: 'POST',
			url: 'ai/create-itenary',
			options: {
				data: formData,
			},
			auth: true,
		});

		if (statusCode === 201 || statusCode === 200) {
			toast({
				title: 'Successfully created itinerary',
				status: 'success',
				duration: 3000,
				isClosable: true,
			});
			setActivities(data.itinerary_days); // Assuming the response contains activities data
			// setNumberOfPeople('');
			// setTripDuration('');
			// setBudget('');
			// setStartDate('');
			// setTravelType('');
			setShowInteractiveMessage(true);
		} else {
			toast({
				title: error?.detail || 'Something went wrong.',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	return (
		<Box pb='20px'>
			<Box maxW={'276px'}>
				<InfoBox text='Please help with the following details for me to be able to create a tailor-made experience for you' />

				<InfoBox pb={pixels['20pixels']} mt={pixels['20pixels']}>
					<Box w={'100%'}>
						<TravelTagline text='How many of you will travel?' />
						<Select
							w='100%'
							mt={pixels['10pixels']}
							bg='white'
							color='rgba(22, 24, 27, 1)'
							placeholder='Select number'
							value={numberOfPeople}
							onChange={(e) => setNumberOfPeople(e.target.value)}>
							{Array.from({ length: 10 }, (_, i) => i + 1).map((number) => (
								<option key={number} value={number}>
									{number}
								</option>
							))}
						</Select>
					</Box>

					<Box w={'100%'} mt={pixels['14pixels']}>
						<TravelTagline text='Specify trip duration' />
						<Select
							mt={pixels['10pixels']}
							bg='white'
							color='rgba(22, 24, 27, 1)'
							placeholder='Select number in days'
							value={tripDuration}
							onChange={(e) => setTripDuration(e.target.value)}>
							{Array.from({ length: 10 }, (_, i) => i + 1).map((number) => (
								<option key={number} value={number}>
									{number}
								</option>
							))}
						</Select>
					</Box>

					<Box w={'100%'} mt={pixels['14pixels']}>
						<TravelTagline text='Specify trip type (Optional)' />
						<RadioGroup
							mt={pixels['20pixels']}
							p={pixels['20pixels']}
							bgColor={'white'}
							onChange={setTravelType}
							value={travelType}>
							<Stack direction='row' gap={pixels['65pixels']}>
								<Radio value='Partner'>Partner</Radio>
								<Radio value='Family'>Family</Radio>
							</Stack>
							<Stack
								mt={pixels['20pixels']}
								direction='row'
								gap={pixels['65pixels']}>
								<Radio value='Friends'>Friends</Radio>
								<Radio value='Solo'>Solo</Radio>
							</Stack>
						</RadioGroup>
					</Box>

					<Box w={'100%'} mt={pixels['14pixels']}>
						<TravelTagline text='When does your trip start (Optional)' />
						<Input
							w='100%'
							bg='white'
							placeholder='Select Date and Time'
							size='md'
							type='date'
							value={startDate}
							onChange={(e) => setStartDate(e.target.value)}
						/>
					</Box>

					<Box mt={pixels['14pixels']}>
						<TravelTagline text='What is your budget?' />
						<InputGroup mt={pixels['10pixels']}>
							<InputLeftAddon children='INR' />
							<Input
								value={budget}
								onChange={(e: any) => setBudget(e.target.value)}
								type='number'
								placeholder='Enter budget'
								bg='white'
								color='rgba(22, 24, 27, 1)'
							/>
						</InputGroup>
					</Box>
					<TrBlackButton
						fontSize={'14px'}
						mt={pixels['20pixels']}
						w='100%'
						onClick={handleSubmit}>
						{loading ? 'Generating...' : 'Generate'}
					</TrBlackButton>
				</InfoBox>

				{error && <Text color='red.500'>Error: {error}</Text>}

				{activities && activities.length > 0 && (
					<>
						{' '}
						<InfoBox
							mt={pixels['20pixels']}
							text='Here are a few recommendations. Provide me with additional details to further refine your experience'></InfoBox>
						<Box pb={pixels['100pixels']} mt={pixels['50pixels']}>
							<ItineraryGrid cards={activities} />
						</Box>
					</>
				)}

				{showInteractiveMessage && (
					<>
						{' '}
						<InfoBox
							// mt={pixels['20pixels']}
							text='
Do you want to add any other country to your itinerary? Some great neighbouring countries are Norway, Sweden and Greenland.
					
					'></InfoBox>
					</>
				)}
			</Box>
			{showInteractiveMessage && <ChatInput />}
		</Box>
	);
};

export default CreateItenary;
