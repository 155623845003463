import { Avatar, Box, Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import { pixels } from '../theme';
import TrPrimaryButton from './TrPrimaryButton';
import TrOutlineButton from './TrOutlineButton';
import TrCircleButton from './TrCircleButton';
import { useContext } from 'react';
import useAxios from '../hooks/useAxios';
import { AppContext } from '../context/AppContext';
import TrPopOverMap from './TrPopOverMap';

const HighlightTitle = ({ title, description }) => {
	return (
		<Tooltip label={`${title} - ${description}`} hasArrow>
			<Text
				sx={{
					color: 'white',
					fontFamily: 'Poppins',
					fontSize: { 'base': '11px', '2xl': '14px' },
					fontWeight: 500,
					lineHeight: { 'base': '110%', '2xl': '130%' },
				}}
				noOfLines={1}>
				<span style={{ fontWeight: 800 }}>{title}</span> -{' '}
				<span style={{ color: 'rgba(255, 255, 255, 0.63)' }}>
					{description}
				</span>
			</Text>
		</Tooltip>
	);
};

const SavedPlacesCard = (props) => {
	const TrCircle = ({ children }) => {
		return (
			<TrCircleButton
				w={pixels['28pixels']}
				h={pixels['28pixels']}
				minW={pixels['28pixels']}
				background={'rgba(2, 6, 12, 0.33)'}
				backdropFilter='blur(2px)'
				aria-label='circle button'>
				{children}
			</TrCircleButton>
		);
	};

	const { fetchData } = useAxios();
	const { shareModal, likeEntities, setLikeEntities } = useContext(AppContext);

	const handleShareClick = (e) => {
		e.stopPropagation();
		shareModal.handleShareClick({
			url: '',
			title: '',
		});
	};

	const handleLike = async (e) => {
		e.stopPropagation();
		const token = localStorage.getItem('token');
		console.log(token);
		if (token) {
			const body = {
				previewImage: props.previewImage,
				city: props.city,
				country: props.country,
				title: props.title,
				tags: props.tags,
				description: props.description,
			};

			const dislikeEntity = async () => {
				await fetchData({
					method: 'POST',
					url: 'user/dislikeEntity',
					options: {
						data: { ...body, token: localStorage.getItem('token') },
						headers: { 'Content-Type': 'application/json' },
					},
					auth: false,
				});
			};

			dislikeEntity();

			// removing the entity from current likeEntitiess
			const tempLikeEntities = [];
			for (let entity of likeEntities) {
				if (
					!(entity['title'] === props.title && entity['city'] === props.city)
				) {
					tempLikeEntities.push(entity);
				}
			}
			setLikeEntities(tempLikeEntities);

			// set the like status context for the SearchResult Screen

			// for (let i = 0; i < videos.length; i++) {
			//     for (let j = 0; j < videos[i].highlights.length; j++) {
			//       let check = true;
			//       for (let k = 0; k < likeEntities.length; k++) {
			//         if (
			//           likeEntities[k]["city"] === city &&
			//           likeEntities[k]["title"] === videos[i]["highlights"][j]["title"]
			//         ) {
			//           check = false;
			//           break;
			//         }
			//       }

			//       // entity not found previously liked
			//       if (check) {
			//         video.highLights.push(false);
			//       }
			//       // found liked
			//       else {
			//         video.highLights.push(true);
			//       }
			//     }
			//     index++;
			//   }
		}
	};

	return (
		<>
			{/* desktop */}
			<Box
				maxWidth={{ 'lg': '198px', '2xl': '246px' }}
				display={{ base: 'none', md: 'block', lg: 'block' }}>
				<Flex
					key={props.title}
					position={'relative'}
					width={{ 'lg': '198px', '2xl': '246px' }}
					height={{ 'lg': '113px', '2xl': '140px' }}
					flexShrink='0'>
					<Flex
						flexDir={'column'}
						gap={{ 'base': pixels['13pixels'], '2xl': '17px' }}>
						<Avatar
							borderRadius={{
								'base': pixels['16pixels'],
								'2xl': pixels['20pixels'],
							}}
							w={'100%'}
							h={{
								'base': pixels['113pixels'],
								'2xl': pixels['140pixels'],
							}}
							src={props?.previewImage}
							name={props?.title}
						/>
						<Flex
							position='absolute'
							top='6px'
							right='6px'
							gap={pixels['12pixels']}>
							<TrCircle aria-label='like'>
								<Image
									w={pixels['14pixels']}
									h={pixels['14pixels']}
									src='icons/like.svg'
									alt='like'
									// onClick={handleLike}
								/>
							</TrCircle>
							<TrCircle aria-label='share'>
								<Image
									onClick={handleShareClick}
									w={pixels['14pixels']}
									h={pixels['14pixels']}
									src='icons/share2.svg'
									alt='share'
								/>
							</TrCircle>
						</Flex>
						<Flex
							w={'100%'}
							gap={pixels['5pixels']}
							justifyContent={'space-between'}>
							<Text
								color='rgba(255, 255, 255, 0.93)'
								fontFamily='Poppins'
								fontSize={{ 'base': '13px', '2xl': '16px' }}
								fontStyle='normal'
								fontWeight='800'
								my='auto'
								lineHeight='115%'>
								{props?.title}
							</Text>
							<Box
								onClick={(e) => {
									e.stopPropagation();
								}}
								position={'relative'}
								zIndex={10000000000}>
								<TrPopOverMap />
							</Box>
						</Flex>
						<Text
							minH={pixels['55pixels']}
							color='rgba(255, 255, 255, 0.63)'
							fontFamily='Poppins'
							fontSize={{
								'base': pixels['11pixels'],
								'2xl': pixels['14pixels'],
							}}
							fontStyle='normal'
							fontWeight='500'
							my='auto'
							noOfLines={3}
							lineHeight={{ 'base': '110%', '2xl': '135%' }}>
							{props?.description}
						</Text>
						<Flex
							maxW={'100%'}
							flexDir={'column'}
							gap={{ 'base': '3px', '2xl': '4px' }}>
							<HighlightTitle title='Vibe' description={props?.vibe || 'N/A'} />
							<HighlightTitle
								title='Best time to visit'
								description={props?.best_time_to_visit || 'N/A'}
							/>
						</Flex>
						<Flex gap={'8px'}>
							<TrOutlineButton
								w={'50%'}
								h={{
									'base': pixels['27pixels'],
									'2xl': pixels['34pixels'],
								}}
								fontSize={{
									'base': pixels['10pixels'],
									'2xl': '13px',
								}}>
								Details
							</TrOutlineButton>{' '}
							<TrPrimaryButton
								width={'50%'}
								h={{
									'base': pixels['27pixels'],
									'2xl': pixels['34pixels'],
								}}
								fontSize={{
									'base': pixels['10pixels'],
									'2xl': '13px',
								}}>
								Book
							</TrPrimaryButton>{' '}
						</Flex>
					</Flex>
				</Flex>
			</Box>
			{/* mobile */}
			<Box display={{ base: 'block', md: 'none', lg: 'none' }}>
				<Flex
					key={props.title}
					position={'relative'}
					width='100%'
					flexShrink='0'
					flexDir={'column'}
					gap={{ 'base': '11px', '2xl': '13px' }}>
					<Image
						src={props?.previewImage}
						alt='video highlight'
						borderRadius={pixels['20pixels']}
						objectFit={'cover'}
						backgroundColor={'white'}
						w={'100%'}
						h={'130px'}
						border={'none'}
					/>

					<Flex
						position='absolute'
						top='6px'
						right='6px'
						gap={pixels['5pixels']}>
						<TrCircle aria-label='like'>
							<Image
								w={pixels['14pixels']}
								h={pixels['14pixels']}
								src='icons/heart-white.svg'
								alt='like'
								onClick={handleLike}
							/>
						</TrCircle>
						<TrCircle aria-label='share'>
							<Image
								w={pixels['14pixels']}
								h={pixels['14pixels']}
								src='icons/share2.svg'
								alt='share'
							/>
						</TrCircle>
					</Flex>
					<Flex justifyContent={'space-between'}>
						<Text
							color='rgba(255, 255, 255, 0.93)'
							fontFamily='Poppins'
							fontSize='14px'
							fontWeight='800'
							my='auto'
							lineHeight='125%'
							flex={4}>
							{props?.title}
						</Text>
						<Box
							sx={{
								borderRadius: '50%',
								background: 'rgba(255, 255, 255, 0.13)',
								backdropFilter: 'blur(2px)',
								h: '25px',
								w: '25px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<Image src='icons/map_pin.svg' alt='map_pin' />
						</Box>
					</Flex>
					<Text
						minH={pixels['55pixels']}
						color='rgba(255, 255, 255, 0.63)'
						fontFamily='Poppins'
						fontSize={pixels['14pixels']}
						fontStyle='normal'
						fontWeight='500'
						my='auto'
						lineHeight='135%'
						noOfLines={2}>
						{props?.description}
					</Text>
					<Flex flexDir={'column'} gap={'10px'}>
						<HighlightTitle title='Vibe' description={props?.vibe} />
						<HighlightTitle
							title='Best time to visit'
							description={props?.best_time_to_visit}
						/>
					</Flex>
					<Flex gap={'8px'}>
						<TrOutlineButton w={'50%'} h={pixels['34pixels']} fontSize={'13px'}>
							Details
						</TrOutlineButton>{' '}
						<TrPrimaryButton w={'50%'} h={pixels['34pixels']} fontSize={'13px'}>
							Book
						</TrPrimaryButton>{' '}
					</Flex>
				</Flex>
			</Box>
		</>
	);
};

export default SavedPlacesCard;
