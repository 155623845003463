import { useState } from 'react';
import {
	Box,
	Checkbox,
	Container,
	Divider,
	Heading,
	HStack,
	Link,
	Stack,
	Text,
	FormControl,
	FormHelperText,
	Alert,
	AlertIcon,
} from '@chakra-ui/react';
import { PasswordField } from '../components/TrPasswordField';
import { OAuthButtonGroup } from '../components/OAuthButtonGroup';
import { pixels } from '../theme';
import TrLink from '../components/TrLink';
import TrButton from '../components/TrButton';
import TraverseLogo from '../components/TraverseLogo';
import useAxios from '../hooks/useAxios';
import PhoneNumberInput from '../components/PhoneNumberInput';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
const validateFields = (values: any) => {
	const errors: any = {};

	if (!values.phone_number) {
		errors.phone_number = 'Phone number is required';
	} else if (!isValidPhoneNumber(values.phone_number)) {
		errors.phone_number = 'Invalid phone number';
	}

	if (!values.password) {
		errors.password = 'Password is required';
	}

	return errors;
};

const Signin: React.FC = () => {
	const navigate = useNavigate();
	const { loading, fetchData } = useAxios();
	const {
		data: data2,
		error: error2,
		loading: loading2,
		fetchData: fetchData2,
	} = useAxios();
	const initLoginFields = {
		phone_number: '',
		password: '',
	};
	const [loginFields, setLoginFields] = useState(initLoginFields);
	const [errors, setErrors] = useState<any>({});
	const [serverError, setServerError] = useState<string>('');
	const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name: string = event.target.name;
		const value: string = event.target.value;
		setErrors({
			...errors,
			[name]: '',
		});
		setLoginFields((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const setPhoneValue = (value: any) => {
		setErrors({
			...errors,
			phone_number: '',
		});
		setLoginFields({
			...loginFields,
			phone_number: value,
		});
	};

	const login = async () => {
		const formErrors = validateFields(loginFields);

		if (Object.keys(formErrors).length === 0) {
			const { data, statusCode, error } = await fetchData({
				method: 'POST',
				url: 'login',
				options: {
					data: loginFields,
					headers: { 'Content-Type': 'application/json' },
				},
				auth: false,
			});
			if (statusCode === 200) {
				setErrors({
					initLoginFields,
				});
				setLoginFields(initLoginFields);
				localStorage.setItem('token', data?.token);
				navigate('/');
			} else {
				setServerError(JSON.stringify(error));
			}
		} else {
			setErrors(formErrors);
		}
	};

	const socialAuth = async () => {
		await fetchData2({
			method: 'GET',
			url: 'authorize/facebook',
			options: {
				headers: { 'Content-Type': 'application/json' },
			},
			auth: false,
		});
		console.log('data2', data2);
		console.log('error2', error2);
	};

	return (
		<Container
			color='trTextColor'
			background={'#fcfdfe'}
			h='100vh'
			maxW='lg'
			py={{ base: '12', md: '24' }}
			px={{ base: '0', sm: '8' }}>
			<Stack spacing='8'>
				<Stack spacing='6'>
					<Stack spacing={{ base: '2', md: '3' }} textAlign='center'>
						<Box mb={pixels['20pixels']}>
							<TraverseLogo />
						</Box>
						<Heading fontSize={{ base: pixels['30pixels'] }}>Login</Heading>

						<Text color='fg.muted'>
							Do you not have an account? <Link href='signup'>Sign up</Link>
						</Text>
					</Stack>
				</Stack>
				{serverError ? (
					<Alert status='error'>
						<AlertIcon />
						{serverError || serverError !== null
							? serverError
							: 'There was an error processing your request'}
					</Alert>
				) : (
					''
				)}
				<Box
					backgroundColor={'trWhiteColor'}
					py={{ base: '0', sm: '8' }}
					px={{ base: '4', sm: '10' }}
					bg={{ base: 'transparent', sm: 'bg.surface' }}
					boxShadow={{ base: 'none', sm: 'md' }}
					borderRadius={{ base: 'none', sm: 'xl' }}>
					<Stack spacing='6'>
						<Stack spacing='5'>
							<FormControl>
								<Text pb='8px'>Phone Number: {loginFields.phone_number}</Text>
								<PhoneNumberInput
									isInvalid={errors.phone_number}
									isDisable={loading || loading2}
									value={loginFields.phone_number}
									onChange={(value) => setPhoneValue(value)}
								/>
								<FormHelperText color={'red'}>
									{errors.phone_number}
								</FormHelperText>
							</FormControl>

							<PasswordField
								value={loginFields.password}
								errorMessage={errors.password}
								label='Password'
								isInvalid={errors.password}
								isDisabled={true || loading2}
								name='password'
								onChange={handleFormChange}
							/>
						</Stack>
						<HStack justify='space-between'>
							<Checkbox size='sm' defaultChecked>
								Remember me
							</Checkbox>
							<Box as={TrLink} to='/forgot-password'>
								<Text fontSize={'sm'}>Forgot password?</Text>
							</Box>
						</HStack>
						<Stack spacing='6'>
							<TrButton
								onClick={login}
								isLoading={loading}
								isDisabled={loading2}>
								Sign innn
							</TrButton>
							<HStack>
								<Divider />
								<Text textStyle='sm' whiteSpace='nowrap' color='fg.muted'>
									or continue with
								</Text>
								<Divider />
							</HStack>
							<OAuthButtonGroup
								isDisabled={loading}
								onClick={socialAuth}
								isLoading={loading2}
							/>
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</Container>
	);
};

export default Signin;
