import { Box, Flex, Image, Text } from '@chakra-ui/react';
import TrCircleButton from '../TrCircleButton';
import { colors, pixels } from '../../theme';

const CountryPageDetailHeader = ({ city, country, ratings, comments }: any) => {
	return (
		<Flex justifyContent={'space-between'} width={'100%'}>
			<Box>
				<Text color={colors.trBlack01}>{city || 'Hanoi'}</Text>
				<Flex>
					<Text
						fontSize={{ base: pixels['10pixels'], lg: 'inherit' }}
						mr={pixels['5pixels']}
						color={'#15141654'}>
						City .{city || country}.
					</Text>
					<Text
						fontSize={{ base: pixels['10pixels'], lg: 'inherit' }}
						fontWeight={'bold'}
						color={'#151416'}>
						⭐️ {ratings || 4.7} ( {comments || '3K'} reviews )
					</Text>
				</Flex>
			</Box>
			<Flex gap={pixels['10pixels']}>
				<TrCircleButton
					background='rgba(18, 17, 19, 0.07)'
					boxShadow={'none'}
					border='none'
					aria-label=''>
					<Image src='/icons/like_black.svg' />
				</TrCircleButton>
				<TrCircleButton
					background='rgba(18, 17, 19, 0.07)'
					boxShadow={'none'}
					border='none'
					aria-label={''}>
					<Image src='/icons/share_black.svg' />
				</TrCircleButton>
			</Flex>
		</Flex>
	);
};

export default CountryPageDetailHeader;
