import { useEffect, useState } from 'react';

interface UseTextEllipsisOptions {
	wordsLimit: number;
}

interface UseTextEllipsisResult {
	truncatedText: string;
	isExpanded: boolean;
	toggleExpanded: () => void;
	valid: boolean;
}

const useTextEllipsis = (
	text: string = '',
	options: UseTextEllipsisOptions,
): UseTextEllipsisResult => {
	const { wordsLimit } = options;
	const [words, setWords] = useState<string[]>([]);
	const [valid, setValid] = useState<boolean>(false);
	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	useEffect(() => {
		if (text) {
			const newWords = text.split(' ');
			setWords(newWords);
			setValid(newWords.length > wordsLimit);
		} else {
			setWords([]);
			setValid(false);
		}
	}, [text, wordsLimit]);

	const truncatedText = isExpanded
		? text
		: words.slice(0, wordsLimit).join(' ') +
		  (words.length > wordsLimit ? '...' : '');

	const toggleExpanded = () => {
		setIsExpanded(!isExpanded);
	};

	return { truncatedText, isExpanded, toggleExpanded, valid };
};

export default useTextEllipsis;
