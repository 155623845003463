import { ReactNode } from 'react';
import { Text, BoxProps, Flex, Image } from '@chakra-ui/react';

interface TextHeaderProps {
	children: ReactNode;
	sx?: BoxProps['sx']; // Define 'sx' prop type
}

const LocationText = ({ children, sx }: TextHeaderProps) => {
	return (
		<Flex gap='4px'>
			<Image w='12px' src='./icons/map-pin.svg' alt='traverse map pin' />
			<Text
				color='#284B63'
				fontFamily='Source Sans Pro'
				fontSize='md_12'
				fontStyle='normal'
				fontWeight='normal'
				lineHeight='24px'
				letterSpacing='0.25px'
				my='auto'
				sx={sx} // Pass 'sx' prop to Text component
			>
				{children}
			</Text>
		</Flex>
	);
};

export default LocationText;
