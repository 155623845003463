import { Link } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface TraverseLogoProps {
	url?: string;
}
const TraverseLogo: React.FC<TraverseLogoProps> = (props) => {
	return (
		<Link
			textDecoration={'none !important'}
			outline={'none'}
			color='white !important'
			zIndex={20}
			fontFamily='Poppins'
			fontSize={{ base: 'medium', lg: 'md' }}
			fontStyle='normal'
			fontWeight={'bold'}
			lineHeight='lineheight-20'
			letterSpacing='0.25px'
			as={RouterLink}
			textTransform={'uppercase'}
			to={props.url || '/'}>
			{'Traverse'}
		</Link>
	);
};

export default TraverseLogo;
