import React from 'react';
import { Box } from '@chakra-ui/react';

interface VideoThumbnailProps {
	thumbnail: string;
	key: any;

	isActive?: boolean;
}

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({
	thumbnail,
	key,
	isActive,
}) => {
	return (
		<Box
			_hover={{ opacity: 0.8 }}
			_active={{ opacity: 0.7 }}
			border={isActive ? '1px solid red' : 'none'}
			cursor={'pointer'}
			key={key}
			width={{ 'base': `calc(56px * 0.8)`, '2xl': '56px' }}
			height={{ 'base': `calc(56px * 0.8)`, '2xl': '56px' }}
			textAlign='center'
			borderRadius='12px'
			opacity={0.33}
			background={`lightgray 50% / cover no-repeat`}
			backgroundImage={`url(${thumbnail})`}
		/>
	);
};

export default VideoThumbnail;
