import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import TrCircleButton from '../TrCircleButton';
import TrPrimaryButton from '../TrPrimaryButton';
import { pixels } from '../../theme';

interface CustomButtonProps {
	onClick: () => void;
	label: string;
	position: 'left' | 'right';
}

const CustomButton: React.FC<CustomButtonProps> = ({
	onClick,
	label,
	position,
}) => (
	<TrCircleButton
		w='12.5px'
		top='50%'
		left={position === 'left' ? '5px' : undefined}
		right={position === 'right' ? '5px' : undefined}
		position='absolute'
		background='transparent'
		_hover={{ background: 'transparent' }}
		_active={{ background: 'transparent' }}
		zIndex={200}
		width='12.5px'
		height='12.5px'
		onClick={onClick}
		aria-label={label}>
		<Image
			width='12.5px'
			height='12.5px'
			src={
				position === 'left'
					? '/icons/left_anchor.svg'
					: '/icons/right_anchor.svg'
			}
		/>
	</TrCircleButton>
);

interface SliderProps {
	items: { image: string; alt: string }[];
}

const CountryPageDetailImageSlider: React.FC<SliderProps> = ({ items }) => {
	return (
		<Box background={'#fff'} position={'relative'}>
			<TrPrimaryButton
				background={'trBlack'}
				border='none'
				_hover={{
					background: 'transparent',
					border: '1px solid trBlack',
				}}
				colorScheme='trBlack'
				height={'auto'}
				fontSize={'12px'}
				py={pixels['10pixels']}
				position={'absolute'}
				right={{ base: pixels['5pixels'], lg: pixels['20pixels'] }}
				bottom={{ base: pixels['30pixels'], lg: pixels['20pixels'] }}
				zIndex={300}
				width={'max-content'}>
				View all photos
			</TrPrimaryButton>
			<Carousel
				// width={'694px'}
				className='tr-single-slider'
				renderArrowPrev={(onClickHandler, hasPrev, label) =>
					hasPrev && (
						<CustomButton
							onClick={onClickHandler}
							label={label}
							position='left'
						/>
					)
				}
				renderArrowNext={(onClickHandler, hasNext, label) =>
					hasNext && (
						<CustomButton
							onClick={onClickHandler}
							label={label}
							position='right'
						/>
					)
				}
				renderIndicator={(onClickHandler, isSelected, index, label) => (
					<button
						type='button'
						onClick={onClickHandler}
						key={index}
						aria-label={`${label} ${index + 1}`}
						style={{
							background: isSelected ? '#000' : '#fff',
							width: '8px',
							height: '8px',
							margin: '0 5px',
							borderRadius: '50%',
							border: 'none',
							cursor: 'pointer',
						}}
					/>
				)}
				autoPlay
				showStatus={false}
				showIndicators
				showThumbs={false}
				infiniteLoop>
				{items &&
					items?.map((slide: any, index) => (
						<Box
							key={index}
							borderRadius={{ base: 0, lg: '20px' }}
							overflow='hidden'>
							<Image
								borderRadius={{ base: 0, lg: '20px' }}
								src={slide}
								alt={''}
								height={{ base: '300px', lg: '555px' }}
								width={{ base: '100%', lg: '694px' }}
								maxW={{ base: '100%', lg: '694px' }}
							/>
						</Box>
					))}
			</Carousel>
		</Box>
	);
};

export default CountryPageDetailImageSlider;
