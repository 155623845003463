import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

const SideBar = () => {
	const [section, setSection] = useState(0);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 560) {
				setSection(1);
			} else {
				setSection(0);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<Box display={{ base: 'none', lg: 'block', md: 'block' }}>
			<Box
				bg='black'
				w='40px'
				h='300px'
				position='fixed'
				right='0'
				top='35%'
				transform='translateY(-50%)'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				alignItems='center'
				p='10px 0'
				clipPath='polygon(0% 10%, 100% 0%, 100% 100%, 0% 90%)'>
				<Flex direction='column' alignItems='center'>
					<Text
						transform='rotate(90deg)'
						writingMode='vertical-rl'
						fontSize='15px'
						fontWeight='bold'
						whiteSpace='nowrap'
						mb={4}
						color={section === 0 ? '#A977FA' : 'grey'}>
						Video highlights
					</Text>
					<Flex
						height='50px'
						alignItems='center'
						justifyContent='center'
						position='relative'
						mb={8}>
						<Box
							position='absolute'
							left='-1px'
							top='0'
							width='100%'
							height='100%'
							background='linear-gradient(to bottom, transparent, purple 50%, transparent)'
							transformOrigin='top left'
							transform='skewX(-20deg)'
						/>
					</Flex>
					<Text
						transform='rotate(90deg)'
						writingMode='vertical-rl'
						fontSize='15px'
						whiteSpace='nowrap'
						color={section === 1 ? '#A977FA' : 'grey'}>
						More content
					</Text>
				</Flex>
			</Box>
		</Box>
	);
};

export default SideBar;
