import React, { useContext, useState, useRef } from 'react';
import {
	Box,
	Image,
	useBreakpointValue,
	Progress,
	Flex,
	IconButton,
} from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FaPlay, FaPause } from 'react-icons/fa';
import VideoThumbnail from './VideoThumbnailViewer/Thumbnail';
import TrCircleButton from './TrCircleButton';
import { AppContext } from '../context/AppContext';
import ReactPlayer from 'react-player/lazy';
import { pixels } from '../theme';
import LikeAndShare from './LikeAndShare';
import { VideoSliderContext } from '../context/VideoSliderContext';

interface CustomButtonProps {
	onClick: () => void;
	label: string;
	position: 'left' | 'right';
}

const CustomButton: React.FC<CustomButtonProps> = ({
	onClick,
	label,
	position,
}) => (
	<TrCircleButton
		boxShadow={'none !important'}
		border='none'
		w='12.5px'
		top='50%'
		left={position === 'left' ? '5px' : undefined}
		right={position === 'right' ? '5px' : undefined}
		position='absolute'
		background='transparent'
		_hover={{ background: 'transparent' }}
		_active={{ background: 'transparent' }}
		zIndex={200}
		width='40px'
		height='40px'
		onClick={onClick}
		aria-label={label}>
		<Image
			width='20.5px'
			height='20.5px'
			src={
				position === 'left'
					? '/icons/left_anchor.svg'
					: '/icons/right_anchor.svg'
			}
		/>
	</TrCircleButton>
);

interface SliderProps {
	items: { previewImage: string; alt: string; video_url: string }[];
}

const ImageSlider: React.FC<SliderProps> = ({ items }) => {
	const { searchDetail, currentVideoIndex, setCurrentVideoIndex }: any =
		useContext(AppContext);
	const { playing, setPlaying }: any = useContext(VideoSliderContext);
	const isDesktop = useBreakpointValue({ base: false, lg: true });
	// const [playing, setPlaying] = useState<boolean>(false);
	const [progress, setProgress] = useState<number>(0);
	const [videoStarted, setVideoStarted] = useState<boolean>(false);
	const playerRef = useRef<ReactPlayer>(null);
	const [isHovered, setIsHovered] = useState<boolean>(false);
	const [muted, setMuted] = useState(true);

	const handleSlideChange = (index: number) => {
		setCurrentVideoIndex(index);
	};

	const handleVideoPlayPause = (isPlaying: boolean) => {
		setPlaying(isPlaying);
		if (isPlaying) {
			setMuted(false);
		} else {
			setMuted(true);
		}
	};

	const handleProgressClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		const boundingRect = event.currentTarget.getBoundingClientRect();
		const clickPosition = event.clientX - boundingRect.left;
		const clickPercentage = clickPosition / boundingRect.width;

		if (playerRef.current) {
			playerRef.current.seekTo(clickPercentage, 'fraction');
		}
	};

	const handlePlay = () => {
		setPlaying(true);
		setVideoStarted(true); // Mark video as started
		setTimeout(() => {
			setMuted(false);
		}, 1000);
	};

	return (
		<Box position={'relative'}>
			<Flex
				position='absolute'
				zIndex={20}
				right={{ base: pixels['20pixels'], lg: `calc(20px * 0.8)` }}
				bottom={'100px'}
				top={{ base: 'auto', lg: pixels['20pixels'] }}>
				<LikeAndShare />
			</Flex>
			<Box
				height={{ 'md': 'calc(600px * 0.8)', '2xl': '600px' }}
				display={{ lg: 'inherit' }}>
				<Carousel
					selectedItem={currentVideoIndex}
					key={currentVideoIndex}
					onChange={handleSlideChange}
					className='tr-single-slider'
					renderThumbs={() =>
						items.map((slide, index) => {
							console.log(currentVideoIndex, index);
							return (
								<VideoThumbnail
									// isActive={currentVideoIndex === index}
									thumbnail={slide.previewImage}
									key={index}
								/>
							);
						})
					}
					renderArrowPrev={(onClickHandler, hasPrev, label) =>
						hasPrev && (
							<CustomButton
								onClick={() => {
									handleSlideChange(currentVideoIndex - 1);
									onClickHandler();
									setPlaying(false);
								}}
								label={label}
								position='left'
							/>
						)
					}
					renderArrowNext={(onClickHandler, hasNext, label) =>
						hasNext && (
							<CustomButton
								onClick={() => {
									handleSlideChange(currentVideoIndex + 1);
									onClickHandler();
									setPlaying(false);
								}}
								label={label}
								position='right'
							/>
						)
					}
					showThumbs={isDesktop}
					autoPlay={false}
					showStatus={false}
					showIndicators={false}
					infiniteLoop>
					{items && items.length > 0
						? items.map((slide, index) => (
								<Box
									className='video-preview'
									height={{ 'md': '420px', '2xl': '600px' }}
									w={{ 'lg': '250px', '2xl': '300px' }}
									minH={{ 'base': '3px', 'lg': `420px`, '2xl': '600px' }}
									maxH={{ 'base': '3px', 'lg': `420px`, '2xl': '600px' }}
									key={index}
									borderRadius={{ base: 0, lg: `calc(20px * 0.8)` }}
									overflow='hidden'
									bgImage={`url(${slide.previewImage})`}
									bgSize='cover'
									bgPosition='center'>
									{/* Empty Box for background image */}
								</Box>
						  ))
						: []}
				</Carousel>
			</Box>
			{items && items.length > 0 && items[currentVideoIndex] && (
				<Box
					className='react-player-box'
					// zIndex={10}
					display='block'
					height={{ 'base': '30vh', 'lg': `420px`, '2xl': '100%' }}
					w={{ 'base': '100%', 'lg': '250px', '2xl': '300px' }}
					borderRadius={{ base: 0, lg: `calc(20px * 0.8)` }}
					overflow='hidden'
					position='absolute'
					top={{ base: '150px', lg: 0 }}
					left={0}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}>
					<ReactPlayer
						className='react-player'
						muted={muted}
						ref={playerRef}
						url={items[currentVideoIndex]?.video_url}
						width='100%'
						height='100%'
						playing={playing}
						onPlay={handlePlay}
						onPause={() => handleVideoPlayPause(false)}
						onEnded={() => setMuted(true)}
						onStart={() => {
							setTimeout(() => {
								setMuted(false);
							}, 1000);
						}}
						onProgress={({ played }) => {
							if (playing) {
								setProgress(played * 100);
							}
						}}
						controls={false} // Hide default controls
						fallback={
							<Image
								borderRadius={{ base: 0, lg: `calc(20px * 0.8)` }}
								src={items[currentVideoIndex].previewImage}
								alt={items[currentVideoIndex].alt}
								height={{ base: `calc(800px * 0.8)`, lg: 'auto' }}
								w={{ 'lg': `calc(300px * 0.8)`, '2xl': '300px' }}
								minH={{ 'lg': `calc(485px * 0.8)`, '2xl': '600px' }}
							/>
						}
					/>
					{!playing && videoStarted && (
						<IconButton
							className='play-button'
							icon={<FaPlay />}
							aria-label='Play'
							size='lg'
							bgColor={'transparent'}
							borderRadius={'50%'}
							position='absolute'
							top='50%'
							left='50%'
							transform='translate(-50%, -50%)'
							zIndex={10}
							onClick={() => handleVideoPlayPause(true)}
						/>
					)}
					{playing && isHovered && (
						<IconButton
							icon={<FaPause />}
							aria-label='Pause'
							size='lg'
							bgColor={'transparent'}
							borderRadius={'50%'}
							position='absolute'
							top='50%'
							left='50%'
							transform='translate(-50%, -50%)'
							zIndex={10}
							onClick={() => handleVideoPlayPause(false)}
						/>
					)}
					{playing && (
						<Progress
							colorScheme='pink'
							size='xs'
							value={progress}
							position='absolute'
							bottom='0'
							width='100%'
							onClick={handleProgressClick}
							cursor='pointer'
						/>
					)}
				</Box>
			)}
			{searchDetail && searchDetail?.entities ? (
				<Box
					sx={{
						position: 'absolute',
						bottom: '150px',
						width: `calc(212.78px * 0.8)`,
						height: '31.8px',
						bg: 'rgba(255, 255, 255, 1)',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: '3.7px',
						display: { base: 'none', lg: 'flex' },
						justifyContent: 'center',
						alignItems: 'center',
						fontSize: { 'base': `calc(10.93 * 0.8)`, '2xl': '10.93px' },
					}}>
					📍{searchDetail?.entities[currentVideoIndex]?.title}
				</Box>
			) : (
				''
			)}
		</Box>
	);
};

export default ImageSlider;
