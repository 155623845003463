import React, { FC } from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import { pixels } from '../../theme';

interface CountryDetailDescriptionProps extends TextProps {
	children: React.ReactNode;
}

const CountryDetailDescription: FC<CountryDetailDescriptionProps> = ({
	children,
	...rest
}) => {
	return (
		<Text
			fontSize={{ base: pixels['12pixels'], lg: 'inherit' }}
			className='country-detail-text' // Add class name here if needed
			{...rest}>
			{children}
		</Text>
	);
};

export default CountryDetailDescription;
