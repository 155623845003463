import React from 'react';
import { Flex } from '@chakra-ui/react';
import HotelListItem from './HotelListItem';

interface HotelItem {
	title: string;
	startingPrice: string;
	link: string;
}

interface HotelListProps {
	city: any;
	hotels: HotelItem[];
}

const HotelList: React.FC<HotelListProps> = ({ city, hotels }) => {
	return (
		<Flex direction='column'>
			{/* {city} */}
			{hotels && hotels.length > 0 ? (
				<>
					<HotelListItem
						title={`Hotels to stay in ${city}`}
						startingPrice={hotels[0]?.startingPrice}
						link={'https://www.skyscanner.net/hotels'}
					/>

					<HotelListItem
						title={`Explore top things to see in ${city}`}
						startingPrice={hotels[0]?.startingPrice}
						link={'#'}
					/>

					<HotelListItem
						title={`Explore ${city} tours`}
						startingPrice={hotels[0]?.startingPrice}
						link={'#'}
					/>
					{/* {hotels &&
				hotels.map((hotel: any, index) => (
					<HotelListItem
						key={index}
						title={hotel?.name}
						startingPrice={hotel?.price}
						link={hotel?.website ? hotel?.website : hotel?.google_maps_url}
					/>
				))} */}
				</>
			) : (
				''
			)}
		</Flex>
	);
};

export default HotelList;
