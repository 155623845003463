import {
    Box,
    HStack,
    Badge,
    IconButton,
    Collapse,
    useDisclosure,
    Stack
} from '@chakra-ui/react';
import { MinusIcon, AddIcon } from '@chakra-ui/icons';
import React from 'react';
import GenericItineraryCard from './GenericItineraryCard';

interface ItineraryCardProps {
    itineraryData: {
        type: string;
        label?: string;
        title?: string;
        subtitle?: string;
        locationFrom?: string;
        locationTo?: string;
        Booking?: {
            bookingOf: string;
            bookingPlatform: string;
            BookingName?: string;
            BookingAddress?: string;
            BookingCity?: string;
            BookingImage?: string;
            checkInDate?: string;
            checkOutDate?: string;
        };
        details?: string;
        margin?: string;
        LinkText?: string;
        imageData?: {
            imageUrl?: string;
            title?: string;
            subtitle?: string;
            price?: string;
            linkText?: string;
        }[];
        onLinkButtonClick?: () => void;
    }[];
}

const ItineraryCard: React.FC<ItineraryCardProps> = ({ itineraryData }) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack bg={["#FFFFFF12", "#FFFFFF12", "none"]} mt={'24px'} w='full' borderRadius={'4px'}>
            <Box 
            display={["none","none","block"]}
                borderTop={'1px solid #FFFFFF21'}
                mt={[4, 0, 0]}
            />
            <HStack
                alignItems='baseline'
                justifyContent="space-between"
                mx={[3, 3, 0]} mt={isOpen ? 4 : 3}
                mb={[isOpen ? 0 : 4, isOpen ? 0 : 4, 0]}>

                <Badge
                    bg={isOpen ? ['#AE7CFF', '#AE7CFF', 'none'] : ['#FFFFFF21', '#FFFFFF21', 'none']}  // Conditional color change only for small screens
                    color={["#FFFFFFD4", "#FFFFFFD4", "#FFFFFFED"]}
                    px={[4, 4, 0]}
                    py={2}
                    textTransform={"capitalize"}
                    rounded={['full', 'full', 'none']}  // Rounded only on small screens
                    fontSize={["13px", "13px", "33px"]}  // Apply larger font size for larger screens
                >
                    {itineraryData[0]?.label}
                </Badge>
                <IconButton
                    aria-label={isOpen ? 'Collapse' : 'Expand'}
                    icon={isOpen ? <MinusIcon boxSize={4} /> : <AddIcon />}
                    bg='#FFFFFF0D'
                    color='#FFFFFFA1'
                    borderRadius='full'
                    onClick={onToggle}
                />
            </HStack>
            <Collapse in={isOpen} animateOpacity>
                <Box px={[4, 4, 0]}>
                    {itineraryData.slice(1).map((item, index) => {
                        return (
                            <GenericItineraryCard
                                key={index}
                                title={item.title}
                                subtitle={item.subtitle}
                                ImageCard={item.imageData}
                                locationFrom={item.locationFrom}
                                locationTo={item.locationTo}
                                Booking={item.Booking}
                                details={item.details}
                                LinkText={item.LinkText}
                                margin={item.margin}
                                onLinkButtonClick={item.onLinkButtonClick}
                            />
                        );
                    }

                    )
                    }
                </Box>
            </Collapse>
        </Stack>
    );
};

export default ItineraryCard;
