import {
	Popover,
	PopoverContent,
	PopoverTrigger,
	Image,
	Box,
	PopoverBody,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { pixels } from '../theme';
import MapComponent, { MapTypeId } from './TrMapRenderer';

const TrPopOverMap: React.FC = () => {
	const initialFocusRef = useRef<HTMLButtonElement>(null); // Adjust the type according to your trigger element

	return (
		<Popover
			initialFocusRef={initialFocusRef}
			placement='top'
			closeOnBlur={true}>
			<PopoverTrigger>
				<Box
					sx={{
						borderRadius: '50%',
						background: 'rgba(255, 255, 255, 0.13)',
						backdropFilter: 'blur(2px)',
						h: { 'base': '22.75px', '2xl': pixels['28pixels'] },
						minW: { 'base': '22.75px', '2xl': pixels['28pixels'] },
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: 0,
					}}>
					<Image
						w={{ 'base': '18px', '2xl': pixels['20pixels'] }}
						src='/icons/map_pin.svg'
						alt='map_pin'
					/>
				</Box>
			</PopoverTrigger>
			<PopoverContent
				position={'relative'}
				background={'transparent'}
				minH={'200px'}
				width={'max-content'}
				color='transaparent'
				p={0}
				borderRadius={pixels['20pixels']}
				border={'2px solid rgba(255, 255, 255, 1)'}
				borderColor='none'>
				<Box
					border={'0.46px solid rgba(255, 255, 255, 0.33)'}
					borderRadius={pixels['20pixels']}
					background={'transparent'}
					overflow={'hidden'}
					height={'200px'}
					width={'330px'}>
					<PopoverBody
						zIndex={10000}
						border={'0.46px solid rgba(255, 255, 255, 0.33)'}
						background={'transparent'}
						borderRadius={pixels['20pixels']}
						p={0}
						overflow={'hidden'}
						height={'200px'}
						width={'330px'}>
						<MapComponent
							mapType={MapTypeId.HYBRID}
							places={[{ lng: 10000, lat: 33434 }]}
							// longitude={33434}
						/>
					</PopoverBody>
				</Box>
			</PopoverContent>
		</Popover>
	);
};

export default TrPopOverMap;
