import { Box, SimpleGrid, VStack, Text, Link, Image } from '@chakra-ui/react';
import React from 'react';

interface ImageViewProps {
	imageUrl?: string;
	title?: string;
	subtitle?: string;
	price?: string;
	linkText?: string;
	linkUrl?: string;
	width?: string;
	height?: string;
}

interface ImageGridProps {
	images: ImageViewProps[];
}

const ImageView: React.FC<ImageViewProps> = ({
	imageUrl,
	title,
	subtitle,
	price,
	linkText,
	linkUrl,
	width,
	height,
}) => {
	return (
		<Box>
			<VStack align='start' spacing={0}>
				<Image
					mt='20px'
					mb='12px'
					src={imageUrl}
					borderRadius='12px'
					objectFit='cover'
					w={width}
					h={height}
				/>
				{title && (
					<Text
						color='#FFFFFFA1'
						fontWeight='medium_bold'
						fontSize='13px'
						maxW={'140px'}>
						{title}
					</Text>
				)}
				{subtitle && price && (
					<Text color='#FFFFFF54' fontSize='11px' fontWeight='medium'>
						{subtitle} {price}
					</Text>
				)}

				{linkText && (
					<Link href={linkUrl} color='#AE7CFF' fontWeight='600' fontSize='13px'>
						{linkText}
					</Link>
				)}
			</VStack>
		</Box>
	);
};

const ImageGrid: React.FC<ImageGridProps> = ({ images }) => {
	const isSingleImage = images.length === 1;

	return (
		<Box>
			{isSingleImage ? (
				<ImageView {...images[0]} width='full' height='160px' />
			) : (
				<SimpleGrid columns={[2,2,6]} spacing={'20px'} mx={1}>
					{images.map((imageProps, index) => (
						<ImageView
							key={index}
							{...imageProps}
							width='175px'
							height='120px'
						/>
					))}
				</SimpleGrid>
			)}
		</Box>
	);
};

export default ImageGrid;
