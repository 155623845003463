import { ReactNode } from 'react';
import { BoxProps, Text } from '@chakra-ui/react';

interface TextHeaderProps {
	children: ReactNode;
	sx?: BoxProps['sx'];
}

const TextHeader = ({ children, sx }: TextHeaderProps) => {
	return (
		<Text
			color='#284B63'
			fontFamily='Plus Jakarta Sans'
			fontSize='20px'
			fontWeight='normal'
			lineHeight='24px'
			sx={sx}>
			{children}
		</Text>
	);
};

export default TextHeader;
