import React from 'react';
import { Box } from '@chakra-ui/react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { colors } from '../theme';
interface PhoneNumberInputProps {
	value: string;
	onChange: (value: string) => void;

	isDisable?: boolean;

	isInvalid?: boolean;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
	value,
	onChange,
	isDisable,
	isInvalid,
}) => {
	return (
		<Box
			sx={{
				'height': '58px',
				'border': `1px solid ${!isInvalid ? colors.trGrey : 'red'}`,
				'borderRadius': '8px',
				'display': 'flex',
				'alignItems': 'center',
				'w': '100%',
				'&:focus': {
					border: `1px solid ${colors.trPrimary} !important`,
				},
				'& .PhoneInput': { pl: '20px', border: 'none !important' },
				'& .PhoneInputInput': {
					'border': 'none !important',
					'height': '90%',
					'flex': '1 1',
					'ml': '20px',
					'w': '100%',
					'borderRadius': '50px',
					'borderColor': `${colors.trPrimary} !important`,
					'&:focus': {
						outline: 'none',
						borderColor: `transparent !important`,
					},
				},

				'& .PhoneInput.PhoneInput--focus': {
					height: '90%',
					flex: '1 1',
					w: '100%',
					background: 'transparent',
					borderRadius: '50px',
					borderColor: `${colors.trPrimary} !important`,
				},

				'& .PhoneInput.PhoneInput--focus input': {
					height: '90%',
					flex: '1 1',
					w: '100%',
					background: 'transparent',
					borderRadius: '50px',
					borderColor: `${colors.trPrimary} !important`,
				},
			}}>
			<PhoneInput
				disabled={isDisable}
				style={{
					border: 'none',
					height: '50px',
				}}
				placeholder=''
				//@ts-ignore
				value={value}
				//@ts-ignore
				onChange={onChange}
				defaultCountry='IN'
				//@ts-ignore
				isInvalid={isInvalid}
				// You can pass additional props to customize the input, like country, etc.
			/>
		</Box>
	);
};

export default PhoneNumberInput;
