import { Box,Text,Divider } from "@chakra-ui/react";
import { useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import TopPlaceCard from "./TopPlaceCard";
import useAxios from "../hooks/useAxios";

const HomePageFooter = () => {
  const topPlacesState = useContext(AppContext);
  const { loading, fetchData } = useAxios();

  useEffect(() => {
    const fetchTopPlaces = async () => {
      const { data, statusCode, error } = await fetchData({
        method: "GET",
        url: `ai/top-places`,
        options: {
          data: {},
          headers: { "Content-Type": "application/json" },
        },
        auth: false,
      });

      console.log("top places data", {
        data,
        statusCode,
        error,
        loading,
      });
      topPlacesState.setTopPlaces(data);
    };

    fetchTopPlaces();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* desktop */}
      <Box display={{ base: "none", lg: "block", md: "block" }}>
        <Text color={'white'} fontSize={'larger'} mt={'40px'} ml={'20px'}>Top Cities in Vitenam</Text>
        {topPlacesState.topPlaces ? topPlacesState.topPlaces.map((value, index) => {
          return <TopPlaceCard data={value} key={index} />;
        }): ''}
      </Box>

        {/* mobile */}
      <Box display={{ base: "block", lg: "none", md: "none" }}>
      <Text color={'white'} fontSize={"larger"} mt={'40px'} mb={'20px'} >Top Cities in Vitenam</Text>
      <Divider bg={"#aaa7a7"} />
        {topPlacesState.topPlaces ? topPlacesState.topPlaces.map((value, index) => {
          return <TopPlaceCard data={value} key={index} />;
        }): ''}
      </Box>
    </>
  );
};

export default HomePageFooter;
