import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';
import { pixels } from '../../theme';

const TrBlackButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
	return (
		<Button
			fontSize={pixels['20pixels']}
			colorScheme='black'
			height='48px'
			borderRadius='8px'
			border='1px solid #333'
			background='linear-gradient(180deg, #4F4F4F 0%, #2C2C2C 100%)'
			color='white'
			_hover={{ bg: 'linear-gradient(180deg, #3B3B3B 0%, #1E1E1E 100%)' }}
			_active={{ bg: 'linear-gradient(180deg, #282828 0%, #0F0F0F 100%)' }}
			{...rest}>
			{children}
		</Button>
	);
};

export default TrBlackButton;
