import {
	Divider,
	Container,
	VStack,
	Text,
	Box,
	Button,
	SimpleGrid,
} from '@chakra-ui/react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import FlightInfo from './FlightInfo';
import MoreFromInfluecerCard from './MoreFromInfluecerCard';
import HomePageFooterTitle from './HomePageFooterTitle';
import HomePageFooterWeatherCard from './HomePageFooterWeatherCard';
import HomePageFooterFlightCard from './HomePageFooterFlightCard';

const CustomLeftArrow = ({ onClick }) => {
	const style = {
		position: 'absolute',
		cursor: 'pointer',
		zIndex: '1',
		left: '0%',
		top: '35%',
	};
	return (
		<button onClick={onClick} style={style} className='custom-left-arrow'>
			<img src='/icons/left.svg' alt='SVG Button' width='50' height='50' />
		</button>
	);
};

const CustomRightArrow = ({ onClick }) => {
	const style = {
		position: 'absolute',
		cursor: 'pointer',
		zIndex: '1',
		right: '0px',
		top: '35%',
	};
	return (
		<button
			onClick={onClick}
			className='custom-right-arrow'
			position='absolute'
			style={style}>
			<img src='/icons/right.svg' alt='SVG Button' width='50' height='50' />
		</button>
	);
};

const TopPlaceCard = ({ data }) => {
	const responsive = {
		alldisplay: {
			breakpoint: { max: 5000, min: 0 },
			items: 1,
		},
	};

	return (
		<>
			{/* desktop */}
			<Box display={{ base: 'none', lg: 'block', md: 'block' }}>
				<VStack color={'white'}>
					<Container maxWidth={'100%'} mt={'40px'}>
						<Divider bg={'#aaa7a7'} />
						<HomePageFooterTitle placeName={data['placeName']} />
						<Text fontSize='xs' width={'100%'} color={'#6d6d6d'}>
							Capital City
						</Text>
						<Text
							fontSize='xs'
							width={'65%'}
							color={'#aaa7a7'}
							noOfLines={'2'}
							mt={'10px'}
							mb={'20px'}>
							{data['description']}
						</Text>
						<Carousel
							color='white'
							responsive={responsive}
							customLeftArrow={<CustomLeftArrow />}
							customRightArrow={<CustomRightArrow />}
							containerClass='carousel-container'
							itemClass='carousel-item'
							width={'100%'}
							autoPlay
							autoPlaySpeed={5000}
							rewind={true}>
							{data['moreInfo'].map((value, index) => {
								return (
									<FlightInfo
										weatherInfo={value['weatherInfo']}
										travelInfo={value['travelInfo']}
										places={value['places']}
									/>
								);
							})}
						</Carousel>
					</Container>
				</VStack>
			</Box>
			{/* mobile  */}
			<Box display={{ base: 'block', lg: 'none', md: 'none' }}>
				<VStack mt={'20px'} color={'white'}>
					<HomePageFooterTitle placeName={data['placeName']} />
					<Text fontSize='xs' width={'100%'} color={'#6d6d6d'}>
						Capital City
					</Text>
					<Text
						fontSize='xs'
						width={'100%'}
						color={'#aaa7a7'}
						noOfLines={'4'}
						mt={'10px'}
						mb={'20px'}>
						{data['description']}
					</Text>
					<Box
						w={'100%'}
						p={'0.5px'}
						borderRadius={'20px'}
						bgGradient='linear(to-r,#aaa7a7 , #111012)'>
						<HomePageFooterWeatherCard
							weatherInfo={data.moreInfo[0].weatherInfo}
						/>
					</Box>
					<Box
						w={'100%'}
						p={'0.5px'}
						borderRadius={'20px'}
						bgGradient='linear(to-r,#aaa7a7 , #111012)'>
						<HomePageFooterFlightCard
							travelInfo={data.moreInfo[0].travelInfo}
						/>
					</Box>

					<Container mt={'20px'} p={'0px'}>
						<SimpleGrid columns={2} spacing={5}>
							{data.moreInfo[0].places.slice(0, 4).map((value, index) => {
								return (
									<MoreFromInfluecerCard
										key={index}
										placeName={value['placeName']}
										imageUrl={value['thumbnailUrl']}
										videoUrl={value['videoUrl']}
										boxSize={'180px'}
										width={'100%'}
									/>
								);
							})}
						</SimpleGrid>
					</Container>
					<Button
						w={'100%'}
						bg={'#111012'}
						color={'#AE7CFF'}
						border={'1px solid #6d6d6d'}
						borderLeft={'none'}
						borderRight={'none'}
						borderRadius={'0px'}
						mt={'20px'}>
						See all from Hanoi
					</Button>
				</VStack>
			</Box>
		</>
	);
};

export default TopPlaceCard;
