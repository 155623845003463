import { Box, IconButton, Image } from '@chakra-ui/react';
import { pixels } from '../../theme';
import { useNavigate } from 'react-router-dom';

const ItineraryFooter = () => {
	const router = useNavigate();
	return (
		<Box
			display={{ base: 'flex', md: 'none' }}
			bgImage={'/icons/Bottom_Sheet.png'}
			justifyContent={'space-between'}
			backgroundRepeat={'no-repeat'}
			backgroundSize={'cover'}
			alignItems={'center'}
			px={pixels['50pixels']}
			zIndex={9000}
			w={'100%'}
			h='86px'
			position='fixed'
			bottom={0}>
			{/* Left Side (Opaque) */}

			<IconButton onClick={() => router('/home')} aria-label={''} bg='none'>
				<Image src='/icons/HomeUnselected.svg' />
			</IconButton>

			{/* Middle Transparent Section with Circle */}
			<Box
				bg='transparent'
				h='100%'
				flexBasis='40%'
				display='flex'
				justifyContent='center'
				alignItems='center'
				position='relative'>
				<Box
					position='absolute'
					top='-30px' /* Adjust this to control how much the circle is raised above the footer */
					bg='transparent'
					h='100%'
					w='100%'
					display='flex'
					justifyContent='center'
					alignItems='center'>
					{/* Circular middle item */}

					<IconButton
						onClick={() => router('/home/mybucketlist')}
						borderRadius={'50%'}
						h='max-content'
						w='max-content'
						aria-label={''}
						bg='none'>
						<Image src='/icons/Centre_Button.svg' />
					</IconButton>
				</Box>
			</Box>

			{/* Right Side (Opaque) */}

			<IconButton aria-label={''} bg='none'>
				<Image src='/icons/ListUnselected.svg' />
			</IconButton>
		</Box>
	);
};

export default ItineraryFooter;
