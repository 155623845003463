import { Text, Container, Box } from '@chakra-ui/react';
import { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { VideoSliderContext } from '../context/VideoSliderContext';

const MoreFromInfluecerCard = (props) => {
	const { placeName, imageUrl, index, boxSize, width } = props;
	const { setCurrentVideoIndex } = useContext(AppContext);
	const { setPlaying } = useContext(VideoSliderContext);
	console.log('image url', imageUrl);
	return (
		<Container
			onClick={async () => {
				await setCurrentVideoIndex(index);
				await window.scrollTo({
					top: 0,
					behavior: 'smooth', // This makes the scroll smooth
				});
				await setPlaying(true);
				if (props?.onClose) {
					props.onClose();
				}
			}}
			flexShrink={'0'}
			width={width}
			p={'5px'}
			m={'0px'}>
			{imageUrl && (
				<Box
					boxSize={boxSize}
					borderRadius={'10px'}
					bgImage={`url(${imageUrl})`}
					bgSize='cover'
					bgPosition='center'
					position='relative'
					display='inline-block'>
					<button
						style={{
							position: 'absolute',
							left: '50%',
							top: '50%',
							transform: 'translate(-50%, -50%)',
							zIndex: '1',
							cursor: 'pointer',
							background: 'transparent',
							border: 'none',
						}}>
						<img
							src='/icons/playVideo.svg' // Path to your SVG file relative to the public folder
							alt='SVG Button'
							width='50'
							height='50'
						/>
					</button>
				</Box>
			)}
			<Text
				fontSize={'md'}
				pl={'5px'}
				maxW={'fit-content'}
				fontWeight={'bold'}
				mt={'5px'}
				color={'white'}>
				{placeName}
			</Text>
		</Container>
	);
};

export default MoreFromInfluecerCard;
