import { ButtonProps, Button as ChakraButton } from '@chakra-ui/react';

const style = {
	display: 'flex',
	minWidth: '100px',
	height: '46px',
	borderRadius: '100px',
	border: '1px solid rgba(53, 54, 53, 0.20)',
	justifyContent: 'center',
	alignItems: 'center',
	background: 'linear-gradient(180deg, #EB9533 0%, #B65D12 100%)',
	boxShadow: '0px 4px 6.5px 0px rgba(0, 0, 0, 0.20)',
	color: '#fff',
};
const TrButton = ({
	onClick,
	colorScheme,
	size,
	isDisabled,
	children,
	...rest
}: ButtonProps) => {
	return (
		<ChakraButton
			_hover={{
				border: '1px solid #BE6517',
				background: 'linear-gradient(180deg, #EB9533 0%, #B65D12 100%)',
				boxShadow: '0px 4px 6.5px 0px rgba(0, 0, 0, 0.20)',
				opacity: 0.9,
			}}
			_active={{
				opacity: 0.7,
			}}
			{...style}
			display={'flex'}
			onClick={onClick}
			colorScheme={colorScheme}
			size={size}
			isDisabled={isDisabled}
			{...rest}>
			{children}
		</ChakraButton>
	);
};

export default TrButton;
