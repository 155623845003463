import React from 'react';
import { IconButton, Box, Button, Flex, HStack } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import TripCard from '../components/CreateItinary/TripCard';
import TripDetail from '../components/CreateItinary/TripDetail';
import data from '../components/CreateItinary/data.json';

const dataMap = new Map<number, any>(
    data.destinations.map((item) => [item.id, item])
);

const Itinerary: React.FC = () => {
    const navigation = useNavigate();
    const { id } = useParams<{ id: string }>();

    const destinationId = parseInt(id || '', 10);
    const destination = dataMap.get(destinationId);

    if (!destination) {
        return (
            <Box
                bg='linear-gradient(118.03deg, #272529 5.68%, #111012 59.38%)'
                minH='100vh'
                w='100vw'
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <Box color='white' fontSize='xl'>Destination not found</Box>
            </Box>
        );
    }

    return (
        <Box
            bg={[
                '#141315',
                '#141315',
                'linear-gradient(118.03deg, #272529 5.68%, #111012 59.38%)'
            ]}
            minH='100vh'
            w='100vw'
            position='relative'
            overflow='hidden'
        >
            <Box
                mt={["0", "0", "4.5vh"]}
                mb={"8vh"}
                height={'auto'}
                pl={['0px', '0px', '18%']}
                pr={['0px', '0px', '17%']}
            >
                <Flex justify='space-between' align='center' maxH='68px' p='16px' visibility={["visible", "visible", "hidden"]}>
                    <Box>
                        <IconButton
                            aria-label='Back'
                            icon={<ArrowBackIcon />}
                            colorScheme='gray'
                            variant={'link'}
                            size='lg'
                            onClick={() => {
                                navigation(`/home/itineraries`);
                            }}
                        />
                    </Box>
                    <HStack spacing='10px'>
                        <Button fontSize='13px' fontWeight='600' color='#AE7CFF' variant='link'>
                            + Save Itinerary
                        </Button>
                        <Button bg='inherit' variant='link'>
                            <img src={'/icons/share3.svg'} alt='share Icon' />
                        </Button>
                    </HStack>
                </Flex>
                <TripCard
                    destination={destination.destinationName}
                    dates={destination.dates}
                    numberOfAdults={destination.noOfAdults}
                    onEdit={() => {
                        console.log('Edit button clicked');
                    }}
                    onAddTickets={() => {
                        console.log('Add tickets button clicked');
                    }}
                />
                <TripDetail
                    title={destination.details.title}
                    subtitle={destination.destinationName}
                    description={destination.details.description}
                />
            </Box>
        </Box>
    );
};

export default Itinerary;
