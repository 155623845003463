import {
	Flex,
	useColorModeValue,
	Heading,
	Text,
	Divider,
} from '@chakra-ui/react';

const ItinerarySection = () => {
	const textColor = useColorModeValue('trTextColor');
	return (
		<Flex direction={'column'} p={'10px 15px'} color={textColor}>
			<Heading fontSize={'lg'}>Itinerary</Heading>
			<Flex dir='row' justify={'space-between'} mt={'10px'}>
				<Text>See Suggested Itinerary</Text>
				<button>{'>'}</button>
			</Flex>
			<Divider mt={'10px'} orientation='horizontal' />
		</Flex>
	);
};

export default ItinerarySection;
