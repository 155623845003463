import {
	Image,
	Flex,
	Box,
	VStack,
	Heading,
	Container,
	Text,
	Link,
	useColorModeValue,
} from '@chakra-ui/react';

import TrButton from './TrButton';

const TrVideoHighlightCard = (props) => {
	const textColor = useColorModeValue('trTextColor');
	const buttonColor = useColorModeValue('trButtonColor');

	const { placename, description, imageUrl, key, openDrawer } = props;

	return (
		<Flex height={'150px'} color={textColor} key={key}>
			<Box
				flex='2'
				display={'flex'}
				justifyItems={'center'}
				alignItems={'center'}
				p={'2px 5px'}
				bg={'white'}>
				<Image
					borderRadius={'10px'}
					height={'85%'}
					mb={'8px'}
					src={imageUrl}
					alt='Image'
				/>
			</Box>
			<VStack flex={'3'} bg={'white'} p={'4px 5px'}>
				<Container display={'flex'} p={'0px 0px'}>
					<Heading fontSize={'md'}>{placename}</Heading>
				</Container>

				<Text fontSize={'md'} noOfLines={'3'} color={'#284B63'}>
					{description}
				</Text>

				<Flex
					flexDir={'row'}
					justifyContent={'space-evenly'}
					alignItems={'center'}
					width={'100%'}>
					<Link fontSize={'md'} flex={'3'} mt={'10px'} color={'#0097FF'}>
						<i className='fa fa-map-marker'></i> View Location
					</Link>
					<TrButton
						onClick={openDrawer}
						h={'40px'}
						background={buttonColor}
						flex={'1'}
						border={'none'}>
						Book
					</TrButton>
				</Flex>
			</VStack>
		</Flex>
	);
};

export default TrVideoHighlightCard;
