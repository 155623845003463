import { VStack, Flex, Box, HStack } from '@chakra-ui/react';
import SearchBox from './SearchBox';
import { pixels } from '../theme';
import TrOutlineButton from './TrOutlineButton';
import { useState } from 'react';
import SavedPlaces from './SavedPlaces';
import SavedVideos from './SavedVideos';

const BucketCardDetail = () => {
	const [activeTab, setActiveTab] = useState('1');

	const showOverview = () => {
		setActiveTab('1');
	};
	const showSavedPlaces = () => {
		setActiveTab('2');
	};
	const showSavedVideos = () => {
		setActiveTab('3');
	};
	return (
		<Flex
			overflow={'auto'}
			flexDir={'column'}
			alignItems={'center'}
			bg='linear-gradient(118deg, #272529 5.68%, #111012 59.38%)'
			pt={{ 'base': 0, 'lg': pixels['50pixels'], '2xl': pixels['65pixels'] }}
			pb={pixels['150pixels']}
			w='100%'>
			<SearchBox suggestionBackground='black' />
			<Flex
				flexDir={'column'}
				mt={{ 'base': 0, 'lg': '90px', '2xl': '120px' }}
				maxW={{
					'base': '100%',
					'md': pixels['700pixels'],
					'lg': pixels['894pixels'],
					'2xl': pixels['1100pixels'],
				}}
				gap={pixels['30pixels']}
				mx='auto'
				w={'100%'}>
				<Box w={'100%'}>
					<VStack minH={'100vh'} width={'100%'}>
						<HStack w={'100%'} gap={'14px'}>
							<TrOutlineButton
								fontSize={'20px'}
								borderRadius={'30px'}
								border={
									activeTab === '1' ? '1px solid white' : ' 1px solid grey'
								}
								onClick={showOverview}>
								Overview
							</TrOutlineButton>
							<TrOutlineButton
								fontSize={'20px'}
								borderRadius={'30px'}
								border={
									activeTab === '2' ? '1px solid white' : ' 1px solid grey'
								}
								onClick={showSavedPlaces}>
								Saved places
							</TrOutlineButton>
							<TrOutlineButton
								fontSize={'20px'}
								borderRadius={'30px'}
								border={
									activeTab === '3' ? '1px solid white' : ' 1px solid grey'
								}
								onClick={showSavedVideos}>
								Saved videos
							</TrOutlineButton>
						</HStack>

						{activeTab === '2' && <SavedPlaces />}
						{activeTab === '3' && <SavedVideos />}
					</VStack>
				</Box>
			</Flex>
		</Flex>
	);
};

export default BucketCardDetail;
