import React, { createContext, useState, ReactNode } from 'react';

export interface VideoContextType {
	playing: boolean;
	setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
}

export const VideoSliderContext = createContext<VideoContextType | undefined>(
	undefined,
);

interface AppContextProviderProps {
	children: ReactNode;
}

export const VideoSliderContextProvider: React.FC<AppContextProviderProps> = ({
	children,
}) => {
	const [playing, setPlaying] = useState<boolean>(true);

	return (
		<VideoSliderContext.Provider
			value={{
				playing,
				setPlaying,
			}}>
			{children}
		</VideoSliderContext.Provider>
	);
};
