import React from 'react';
import { Box } from '@chakra-ui/react';
import SearchTextItem from './SearchTextItem';
import SearchSuggestionBackground from './SearhSuggestionBackground';
import { pixels } from '../../theme';

interface SuggestedSearchProps {
	handleSearch: (query: any) => void;
	suggestedSearchItems: string[];

	background?: string;
}

const SuggestedSearch: React.FC<SuggestedSearchProps> = ({
	suggestedSearchItems,
	handleSearch,
	...rest
}) => {
	return (
		<SearchSuggestionBackground
			{...rest}
			minW={{ 'base': '100%', 'lg': pixels['894pixels'], '2xl': '1100px' }}>
			{suggestedSearchItems.map((item, index) => (
				<React.Fragment key={index}>
					<SearchTextItem
						onClick={(e: any) => {
							e.stopPropagation();
							console.log('query param ==>', item);
							if (item) {
								handleSearch(item);
							}
						}}
						hideBorder={index === suggestedSearchItems.length - 1}>
						{item}
					</SearchTextItem>
					{index !== suggestedSearchItems.length - 1 && <Box height='8px' />}
				</React.Fragment>
			))}
		</SearchSuggestionBackground>
	);
};

export default SuggestedSearch;
