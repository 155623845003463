import { Box, Container, useColorModeValue } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import useAuthRouteProtector from '../../hooks/useAuthRouteProtector';
import WebsiteHeader from '../WebsiteHeader';
import { pixels } from '../../theme';
import WebsiteFooter from '../WebsiteFooter';
// import Footer from '../Footer';

function AppLayout() {
	const bg = useColorModeValue('trBgColor', 'trBackgroundColor');
	const { loading } = useAuthRouteProtector();

	if (loading) {
		return <div>Loading...</div>;
	}

	console.log('testing env', process.env.REACT_APP_TEST);
	return (
		<Container
			px={0}
			background={
				'linear-gradient(180deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%)'
			}
			position={'relative'}
			// px={pixels['50pixels']}
			// height={'100vh'}
			maxW={{ 'md': '100%', 'lg': '100%', '2xl': '100%' }}
			bg={bg}
			color='white'>
			<Box
				px={{ base: 0, md: pixels['50pixels'] }}
				zIndex={100000}
				top={pixels['20pixels']}
				left={0}
				right={0}
				width={'1300px'}
				marginX={'auto'}
				position={'fixed'}>
				<WebsiteHeader />
			</Box>
			<Box>
				<Outlet />
			</Box>

			<Box
				mx='auto'
				background='#FFF'
				boxShadow='0px -2px 13.6px 0px rgba(0, 0, 0, 0.15)'
				position={'fixed'}
				bottom={0}
				width={'100%'}
				display={'flex'}>
				<WebsiteFooter />
			</Box>
		</Container>
	);
}

export default AppLayout;
