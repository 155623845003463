import React from 'react';
import { Box, Text, TextProps } from '@chakra-ui/react';
import { pixels } from '../../theme';

interface SearchTextItemProps extends TextProps {
	hideBorder?: boolean;
}

const SearchTextItem: React.FC<SearchTextItemProps> = ({
	children,
	onClick,
	hideBorder = false,
	...rest
}) => {
	return (
		<Box
			onClick={onClick}
			cursor={'pointer'}
			position='relative'
			minH={{ base: '25px', lg: '40px' }}
			w='100%'>
			<Text
				color='rgba(255, 255, 255, 0.83)'
				fontFamily='Poppins'
				fontSize={{ base: pixels['10pixels'], lg: pixels['16pixels'] }}
				fontStyle='normal'
				fontWeight={500}
				lineHeight='18px'
				my={{ base: '12.5px', large: '20px' }}
				{...rest}>
				{children}
			</Text>
			{!hideBorder && (
				<Box
					position='absolute'
					bottom={{ base: '0', lg: '8px' }}
					left='0'
					width='100%'
					height='1px'
					background='rgba(205, 205, 205, 0.09)'
				/>
			)}
		</Box>
	);
};

export default SearchTextItem;
