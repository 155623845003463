import { Flex, FlexProps } from '@chakra-ui/react';
import { pixels } from '../../theme';
import React, { ReactNode } from 'react';

interface CountryContentBoxProps extends FlexProps {
	children?: ReactNode;
}

const CountryContentBox: React.FC<CountryContentBoxProps> = ({
	children,
	...rest
}) => {
	return (
		<Flex
			px={pixels['20pixels']}
			py={pixels['10pixels']}
			w={{ base: '100%', lg: '448px' }}
			h={{ base: '130px', lg: '150px' }}
			border='1px solid #16151754'
			borderRadius={pixels['24pixels']}
			{...rest}>
			{children}
		</Flex>
	);
};

export default CountryContentBox;
