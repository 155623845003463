import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';
import { pixels } from '../theme';

const TrPrimaryButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
	return (
		<Button
			fontSize={pixels['20pixels']}
			colorScheme='trPrimary'
			height='48px'
			borderRadius='8px'
			border='1px solid #9B5CFF'
			background='linear-gradient(180deg, #AE7CFF 0%, #6732BE 100%)'
			color='white'
			_hover={{ bg: 'linear-gradient(180deg, #8F5CE8 0%, #5E2AB8 100%)' }}
			_active={{ bg: 'linear-gradient(180deg, #522F87 0%, #2B173C 100%)' }}
			{...rest}>
			{children}
		</Button>
	);
};

export default TrPrimaryButton;
