import React, { FC } from 'react';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import CountryDetailDescription from './CountryPageDescription';
import { pixels } from '../../theme';

interface CountryDescriptionTabProps {
	description?: string;
	tabs: { title: string; content: React.ReactNode }[];
}

const CountryDescriptionTab: FC<CountryDescriptionTabProps> = ({
	tabs,
	description,
}) => {
	return (
		<Tabs variant='soft-rounded' colorScheme='primary'>
			<TabList gap={pixels['10pixels']}>
				{tabs && tabs.length > 0
					? tabs.map((tab: any, index) => (
							<Tab
								fontSize={{ base: pixels['10pixels'], lg: 'inherit' }}
								_selected={{ color: '#fff', background: '#000' }}
								px={pixels['20pixels']}
								height={{ base: '35px', lg: '45px' }}
								border={'1px solid #646464'}
								key={index}>
								{tab}
							</Tab>
					  ))
					: ''}
			</TabList>
			<TabPanels>
				{tabs && tabs.length > 0
					? tabs.map((tab: any, index) => (
							<TabPanel key={index}>
								<CountryDetailDescription>
									{description}
								</CountryDetailDescription>
							</TabPanel>
					  ))
					: ''}
			</TabPanels>
		</Tabs>
	);
};

export default CountryDescriptionTab;
