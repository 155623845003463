// src/MapComponent.tsx

import React, { useEffect } from 'react';
import {
	MapContainer,
	TileLayer,
	Marker,
	Popup,
	ZoomControl,
	useMap,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen'; // Import the JavaScript part of the plugin

// Fix for default marker icon issue with Webpack
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { pixels } from '../theme';

const DefaultIcon = L.icon({
	iconUrl: markerIcon,
	iconRetinaUrl: markerIcon2x,
	shadowUrl: markerShadow,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

interface Mark {
	latitude: number;
	longitude: number;
}

interface MapComponentProps {
	marks?: Mark[];
}

const FullscreenControl = () => {
	const map = useMap();

	useEffect(() => {
		if (map) {
			// Check if the fullscreen control is already added
			//@ts-ignore
			const fullscreenControlExists = map._controlCorners.bottomright //@ts-ignore
				.querySelector('.leaflet-control-fullscreen');

			if (!fullscreenControlExists) {
				//@ts-ignore
				L.control
					//@ts-ignore
					.fullscreen({
						position: 'bottomright',
						title: 'Expand to fullscreen',
						titleCancel: 'Exit fullscreen',
					})
					.addTo(map);
			}
		}
	}, [map]);

	return null;
};

const MapComponent: React.FC<MapComponentProps> = ({ marks }) => {
	// Provide default center if marks is undefined or empty
	const defaultCenter = [0, 0]; // Change to appropriate default values if needed

	return (
		<MapContainer
			id='map' // Add an ID to the MapContainer for use in the useEffect
			//@ts-ignore
			center={
				marks && marks.length > 0
					? [marks[0].latitude, marks[0].longitude]
					: defaultCenter
			}
			zoom={10}
			style={{
				borderRadius: pixels['20pixels'],
				height: '100%',
				width: '100%',
			}}
			zoomControl={false} // Disable default zoom controls
		>
			<TileLayer
				url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
				//@ts-ignore
				options={{
					attribution:
						'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
				}}
			/>
			{marks?.map((mark, index) => (
				<Marker key={index} position={[mark.latitude, mark.longitude]}>
					<Popup>
						{/* A pretty CSS3 popup. <br /> Easily customizable. */}
					</Popup>
				</Marker>
			))}
			<ZoomControl position='topleft' />
			<FullscreenControl /> {/* Add the fullscreen control component */}
		</MapContainer>
	);
};

export default MapComponent;
