import { useState } from 'react';
import {
	Box,
	FormControl,
	FormHelperText,
	Stack,
	Text,
	Textarea,
	useToast,
} from '@chakra-ui/react';
import UIInput from '../components/UIInput';
import { colors, pixels } from '../theme';
import TrPrimaryButton from '../components/TrPrimaryButton';
import useAxios from '../hooks/useAxios';

const JoinWaitList = () => {
	const [email, setEmail] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const { fetchData, loading } = useAxios();
	const toast = useToast();

	const handleSubmit = async () => {
		if (!email) {
			toast({
				title: 'Email is required.',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
			return;
		}

		const { statusCode, error } = await fetchData({
			method: 'POST',
			url: 'waitlist',
			options: {
				data: {
					email,
					description,
				},
			},
		});

		if (statusCode === 201 || statusCode === 200) {
			toast({
				title: 'Successfully joined the waitlist!',
				status: 'success',
				duration: 3000,
				isClosable: true,
			});
			setEmail('');
			setDescription('');
		} else {
			toast({
				title: JSON.stringify(error?.detail) || 'Something went wrong.',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	return (
		<Box
			px={{ base: 0, md: pixels['50pixels'] }}
			display={{ base: 'block', md: 'flex' }}
			flexDir={{ md: 'row' }}
			minHeight={'100vh'}
			m='auto'
			gap={{ base: pixels['20pixels'], md: pixels['50pixels'] }}
			pt={{ base: pixels['100pixels'], md: pixels['150pixels'] }}
			pb={{ base: pixels['100pixels'], md: pixels['50pixels'] }}
			// mx={'15px'}
			maxW={{ base: '90%', md: '90%', lg: '1250px' }}>
			{/* Background image */}
			<Box
				width={{ base: '100%', md: '50%' }}
				height={{
					'base': '300px',
					'md': '400px',
					'lg': '400px',
					'2xl': '600px',
				}}
				borderRadius={pixels['20pixels']}
				bgImage={'url(/images/travel_bg.jpeg)'}
				bgSize={'cover'}
				bgPosition={'center'}
				bgRepeat={'no-repeat'}
				mb={{ base: pixels['20pixels'], md: '0' }}
			/>

			{/* Form section */}
			<Box
				pb={pixels['50pixels']}
				overflowY={'hidden'}
				overflowX={'auto'}
				width={{ base: '100%', md: '50%' }}
				color='black'>
				<Text
					fontSize={{
						'base': '22px',
						'md': '24px',
						'lg': '24px',
						'2xl': '40px',
					}}
					as='h1'>
					Be among the first to experience the new era of online travel.
				</Text>

				<Text
					fontSize={{
						'base': '12px',
						'md': '14px',
						'lg': '16px',
						'2xl': '18px',
					}}>
					Join our waitlist today and gain access to exclusive perks. We're
					eager to connect with you and tailor our services to meet your needs.
				</Text>
				<Stack
					spacing={pixels['20pixels']}
					mt={{ base: pixels['20pixels'], md: pixels['50pixels'] }}>
					<FormControl>
						<Text pb='8px'>
							What kind of AI-powered travel experiences are you looking forward
							to? (Optional)
						</Text>
						<Textarea
							focusBorderColor={colors.trPrimary}
							borderWidth={'.1px'}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								background: '#fff',
							}}
							value={description}
							onChange={(e) => setDescription(e.target.value)}
						/>
					</FormControl>

					<FormControl isRequired>
						<Text pb='8px'>Your Email*</Text>
						<UIInput
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							type='email'
						/>
						<FormHelperText color={'red'}>
							{/* Add validation message if needed */}
						</FormHelperText>
					</FormControl>

					{/* Updated Join Now button */}
					<TrPrimaryButton
						color='white'
						w='100%'
						py={{ base: pixels['10pixels'], md: pixels['20pixels'] }}
						fontSize={{ base: 'md', md: 'lg' }}
						borderRadius={pixels['10pixels']}
						transition='background-color 0.2s ease'
						onClick={handleSubmit}
						isLoading={loading}>
						Join Waitlist Now
					</TrPrimaryButton>
				</Stack>
			</Box>
		</Box>
	);
};

export default JoinWaitList;
