import { HStack, Heading } from '@chakra-ui/react';
import TrCircleButton from './TrCircleButton';
import { useContext } from 'react';
import { AppContext } from '../context/AppContext';

const HomePageFooterTitle = ({ placeName }) => {
	const { shareModal } = useContext(AppContext);
	const handleShareClick = () => {
		shareModal.handleShareClick({
			url: '',
			title: '',
		});
	};
	return (
		<HStack width={'100%'} mt={'20px'} justifyContent={'space-between'}>
			<Heading>{placeName}</Heading>
			<HStack>
				{/* <TrCircleButton>
					<img src='/icons/like.svg' alt='SVG Button' />
				</TrCircleButton> */}
				<TrCircleButton onClick={handleShareClick}>
					<img
						src='/icons/shareWhite.svg'
						alt='SVG Button'
						width='50'
						height='50'
					/>
				</TrCircleButton>
			</HStack>
		</HStack>
	);
};

export default HomePageFooterTitle;
