import { Box } from '@chakra-ui/react';
import TraverseBlackLogo from './TraverseBlackLogo';
import { pixels } from '../theme';
import { Link, useLocation } from 'react-router-dom';
import TrTealButton from './Buttons/TrTealButton';

const WebsiteHeader = () => {
	const location = useLocation();
	const isIndexPage = location.pathname === '/';

	return (
		<Box
			sx={{
				display: 'flex',
				w: '100%',
				maxW: '100% !important',
				justifyContent: 'space-between',
			}}>
			<TraverseBlackLogo
				//@ts-ignore
				sx={{
					pl: { base: pixels['10pixels'], md: 0 },
					color: isIndexPage ? 'white' : 'black',
					my: 'auto',
					fontSize: pixels['24pixels'],
				}}
			/>

			{isIndexPage && (
				<TrTealButton
					mt={{ base: pixels['40pixels'], md: pixels['40pixels'] }}
					_hover={{
						opacity: 0.7,
					}}
					_active={{
						opacity: 0.5,
					}}
					as={Link}
					//@ts-ignore
					to='/join'
					zIndex={100}
					color='white'>
					Join The waitlist
				</TrTealButton>
			)}
		</Box>
	);
};

export default WebsiteHeader;
