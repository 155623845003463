import React, { createContext, useState, ReactNode } from 'react';

export interface ShareModalProps {
	// onClose: () => void;
	url: string;
	title: string;
	handleShareClick: () => void;

	handleCloseModal: () => void;
}
export interface AppContextType {
	otherPlaces: any[];
	setOtherPlaces: React.Dispatch<React.SetStateAction<any[]>>;
	topPlaces: any[];
	setTopPlaces: React.Dispatch<React.SetStateAction<any[]>>;
	searchDetail: any;
	setSearchDetail: React.Dispatch<React.SetStateAction<any>>;
	searchDetailLoading: boolean;
	setSearchDetailLoading: React.Dispatch<React.SetStateAction<boolean>>;
	currentVideoIndex: number;
	setCurrentVideoIndex: React.Dispatch<React.SetStateAction<number>>;
	shareModal: ShareModalProps;
	setShareModal: React.Dispatch<React.SetStateAction<any>>;
	isShareModalOpen: any;
	likeStatus: any[];
	setLikeStatus: React.Dispatch<React.SetStateAction<any[]>>;
	videoHighlightDetail: any;
	setVideoHighlightDetail: React.Dispatch<React.SetStateAction<any>>;
	videoHighlightDetailLoading: boolean;
	setVideoHighlightDetailLoading: React.Dispatch<React.SetStateAction<any>>;
	likeSearches: any[];
	setLikeSearches: React.Dispatch<React.SetStateAction<any[]>>;
	likeEntities: any[];
	setLikeEntities: React.Dispatch<React.SetStateAction<any[]>>;
	bucketListCard: any[];
	setBucketListCard: React.Dispatch<React.SetStateAction<any[]>>;
	ip: string;
	setIp: React.Dispatch<React.SetStateAction<string>>;
}

export const AppContext = createContext<AppContextType | undefined>(undefined);

interface AppContextProviderProps {
	children: ReactNode;
}

export const AppContextProvider: React.FC<AppContextProviderProps> = ({
	children,
}) => {
	const [otherPlaces, setOtherPlaces] = useState<any[]>([]);
	const [topPlaces, setTopPlaces] = useState<any[]>([]);
	const [searchDetail, setSearchDetail] = useState<any>({});
	const [videoHighlightDetail, setVideoHighlightDetail] = useState<any>({});
	//
	const [isShareModalOpen, setIsShareModalOpen] = useState<any>(undefined);
	const [videoHighlightDetailLoading, setVideoHighlightDetailLoading] =
		useState<boolean>(false);

	const handleShareClick = async (url?: any, title?: any) => {
		console.log(url, title);
		setIsShareModalOpen({ url, title });
		console.log('is share modal open', isShareModalOpen);
		// console.log('share modal props', shareModal);
		// setIsShareModalOpen({ url, title });
		// setShareModal({ ...shareModal, url: url, title: title });
	};

	const handleCloseModal = () => {
		setIsShareModalOpen(false);
	};

	const [shareModal, setShareModal] = useState<ShareModalProps>({
		url: '',
		title: '',
		handleCloseModal,
		handleShareClick,
	});
	const [searchDetailLoading, setSearchDetailLoading] =
		useState<boolean>(false);
	const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0);
	const [likeStatus, setLikeStatus] = useState<any[]>([]);
	const [likeEntities, setLikeEntities] = useState<any[]>([]);
	const [likeSearches, setLikeSearches] = useState<any[]>([]);
	const [bucketListCard, setBucketListCard] = useState<any[]>([]);
	const [ip, setIp] = useState<string>('');
	return (
		<AppContext.Provider
			value={{
				bucketListCard,
				setBucketListCard,
				likeEntities,
				setLikeEntities,
				likeSearches,
				setLikeSearches,
				otherPlaces,
				setOtherPlaces,
				topPlaces,
				setTopPlaces,
				searchDetail,
				setSearchDetail,
				searchDetailLoading,
				setSearchDetailLoading,
				currentVideoIndex,
				setCurrentVideoIndex,
				shareModal,
				setShareModal,
				isShareModalOpen,
				likeStatus,
				setLikeStatus,
				videoHighlightDetail,
				setVideoHighlightDetail,
				videoHighlightDetailLoading,
				setVideoHighlightDetailLoading,
				ip,
				setIp,
			}}>
			{children}
		</AppContext.Provider>
	);
};
