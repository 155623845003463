import { ButtonProps, Button as ChakraButton } from '@chakra-ui/react';

const style = {
	display: 'flex',
	borderRadius: '8px',
	border: '1px solid #fff',
	justifyContent: 'center',
	alignItems: 'center',
	background: 'transparent',
	color: '#fff',
	px: { 'base': '19.5px', '2xl': '24px' },
	py: { 'base': '6.5px', '2xl': '8px' },
};
const TrOutlineButton = ({
	onClick,
	colorScheme,
	size,
	isDisabled,
	children,
	...rest
}: ButtonProps) => {
	return (
		<ChakraButton
			_hover={{
				opacity: 0.9,
			}}
			_active={{
				opacity: 0.7,
			}}
			{...style}
			display={'flex'}
			onClick={onClick}
			colorScheme={colorScheme}
			size={size}
			isDisabled={isDisabled}
			{...rest}>
			{children}
		</ChakraButton>
	);
};

export default TrOutlineButton;
