import { Avatar, BoxProps, Flex, Text } from '@chakra-ui/react';

interface NameAvatarProps {
	avatarUrl?: string;
	name: string;
	sx?: BoxProps['sx']; // Define 'sx' prop type
}

const NameAvatar = (props: NameAvatarProps) => {
	const { name, sx } = props;
	return (
		<Flex sx={sx} gap='8px' justifyContent='space-between'>
			<Avatar
				h='lg_46'
				w='lg_46'
				name='Dan Abrahmov'
				src='https://bit.ly/dan-abramov'
			/>
			<Text
				color='#284B63'
				fontFamily='Roboto'
				fontSize='12px'
				fontStyle='normal'
				fontWeight='lg'
				lineHeight='normal'
				letterSpacing='0.25px'
				my='auto'>
				{name}
			</Text>
		</Flex>
	);
};

export default NameAvatar;
