import { useEffect, useState } from 'react';
import useAxios from './useAxios';
// import { useNavigate } from 'react-router-dom';

function useAuthRouteProtector() {
	const { fetchData } = useAxios();
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [loading, setLoading] = useState(true);
	// const navigate = useNavigate();

	useEffect(() => {
		const checkUserProfile = async () => {
			try {
				const { statusCode, data } = await fetchData({
					method: 'GET',
					auth: true,
					url: 'user/profile',
				});
				console.log('userprofile', data);
				if (statusCode !== 200) {
					console.log('i am here 401', data);
					setIsAuthenticated(false);
					// localStorage.removeItem('token');
					// navigate('/', { replace: false });
					setIsAuthenticated(false);
					return;
				}
				console.log('userprofile', data);
				setIsAuthenticated(true);
			} catch (error: any) {
				setIsAuthenticated(false);
				if (error.response && error.response.status === 401) {
					// localStorage.removeItem('token');
					// navigate('/', { replace: true });
				}
			} finally {
				setLoading(false);
			}
		};

		checkUserProfile();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { isAuthenticated, loading };
}

export default useAuthRouteProtector;
