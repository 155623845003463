import { useContext, useState, useEffect } from 'react';
import { Box, BoxProps, useBreakpointValue } from '@chakra-ui/react';
import SearchInput from './SearchComponents/SearchInput';
import SuggestedSearch from './SearchComponents/SuggestedSearch';
import { pixels } from '../theme';
import { useNavigate, useLocation } from 'react-router-dom';
import useAxios from '../hooks/useAxios';
import { AppContext } from '../context/AppContext';

interface SearchBoxOptions extends BoxProps {
	suggestionBackground?: string;

	dontDoInitSearch?: boolean;

	onClose?: () => void;
}

const SearchBox = (props: SearchBoxOptions) => {
	const { fetchData } = useAxios();
	const navigate = useNavigate();
	const location = useLocation();
	const [showSuggestedBox, setShowSuggestedBox] = useState(false);
	const context: any = useContext(AppContext);
	const {
		setSearchDetail,
		setSearchDetailLoading,
		setLikeStatus,
		likeEntities,
		likeSearches,
	} = context;

	const handleSearchInputFocus = () => {
		setShowSuggestedBox(true);
	};
	const [placeholder, setPlaceholder] = useState<string>('');
	const initializeLikeStatus = async (videos: any[], city: string) => {
		interface Video {
			id: number;
			liked: boolean;
			highLights: boolean[];
		}

		let tempLikeStatus: Video[] = [];
		let index = 0;

		for (let i = 0; i < videos.length; i++) {
			let search_status = false;

			for (let k = 0; k < likeSearches.length; k++) {
				if (videos[i]['url'] === likeSearches[k]['url']) {
					search_status = true;
					break;
				}
			}
			let video: Video = {
				id: index,
				liked: search_status,
				highLights: [],
			};

			for (let j = 0; j < videos[i].highlights.length; j++) {
				let check = true;
				for (let k = 0; k < likeEntities.length; k++) {
					if (
						likeEntities[k]['city'] === city &&
						likeEntities[k]['title'] === videos[i]['highlights'][j]['title']
					) {
						check = false;
						break;
					}
				}

				if (check) {
					video.highLights.push(false);
				} else {
					video.highLights.push(true);
				}
			}
			tempLikeStatus.push(video);
			index++;
		}
		setLikeStatus(tempLikeStatus);
	};

	const handleSearchInputBlur = async (query: any) => {
		console.log('query', query);
		await setPlaceholder(query);
		await localStorage.setItem('query', query);
		if (location.pathname === '/home') {
			return navigate(`/home/search?query=${query}`);
		}
		setTimeout(() => {
			setShowSuggestedBox(false);
		}, 1000);

		if (!query) return;
		setSearchDetailLoading(true);
		const { data, statusCode } = await fetchData({
			method: 'GET',
			url: `ai/search-detail?search_slug=${query}`,
			options: {
				data: {},
				headers: { 'Content-Type': 'application/json' },
			},
			auth: false,
		});
		if (statusCode === 401 || statusCode === 500) {
			localStorage.removeItem('token');
			setSearchDetailLoading(false);
			return;
		}
		setPlaceholder(query);
		setSearchDetail(data);
		setSearchDetailLoading(false);
		initializeLikeStatus(data.entities, data['city']);
	};

	useEffect(() => {
		if (props.dontDoInitSearch) return;
		const searchParams = new URLSearchParams(location.search);
		const queryParam = searchParams.get('query');
		console.log('query param', queryParam);
		if (queryParam) {
			setPlaceholder(placeholder);
			handleSearchInputBlur(queryParam);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.search]);

	const bg = useBreakpointValue({
		base:
			location.pathname === '/home'
				? 'rgba(255, 255, 255, 0.93)'
				: 'rgba(39, 37, 41, 0.53)',
		md: 'rgba(255, 255, 255, 0.93)',
		lg:
			location.pathname === '/home'
				? 'rgba(255, 255, 255, 0.93)'
				: 'rgba(39, 37, 47, 0.53)',
	});

	const inputFontColor = useBreakpointValue({
		base: location.pathname === '/home' ? 'black' : 'white',
		md: 'black',
		lg: location.pathname === '/home' ? 'black' : 'white',
	});

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				mt: { 'base': pixels['40pixels'], '2xl': pixels['50pixels'] },
				w: '100%',
				zIndex: 2,
				position: 'fixed',
				backdropFilter: 'blur(10px)',
				bg: bg,
			}}>
			<SearchInput
				onSearch={(value?: any) => handleSearchInputBlur(value)}
				onBlur={() => {
					setTimeout(() => {
						setShowSuggestedBox(false);
					}, 200);
				}}
				onFocus={handleSearchInputFocus}
				bg={bg}
				inputFontColor={inputFontColor}
				placeholder={placeholder}
				onClose={props?.onClose}
				dontDoInitSearch={props?.dontDoInitSearch}
			/>
			{showSuggestedBox && (
				<Box
					zIndex={100}
					w={{
						'base': '90%',
						'lg': pixels['894pixels'],
						'2xl': pixels['1100pixels'],
					}}
					mt={{ base: 0, lg: pixels['20pixels'] }}
					position={'absolute'}
					top={{ base: pixels['30pixels'], lg: '50px' }}>
					<SuggestedSearch
						handleSearch={handleSearchInputBlur}
						background={props.suggestionBackground}
						suggestedSearchItems={[
							'Top things to do in Thailand...',
							'Best places to visit in Southern Europe...',
							'Best scuba diving places...',
							'Best adventure destinations...',
							'Top things to do in Vietnam...',
						]}
					/>
				</Box>
			)}
		</Box>
	);
};

export default SearchBox;
