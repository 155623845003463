import React from 'react';
import {
    Box,
    HStack,
    Text,
    Button,
    IconButton,
    Image,
    VStack,
} from '@chakra-ui/react';

import { CheckCircleIcon, DownloadIcon } from '@chakra-ui/icons';

interface BookingCardProps {
    bookingPlatform?: string;
    BookingName?: string;
    BookingAddress?: string;
    BookingCity?: string;
    BookingImage?: string;
    bookingOf?: string;
    checkInDate?: string;
    checkOutDate?: string;
    onViewClick?: () => void;
    onDownloadClick?: () => void;
}

const BookingCard: React.FC<BookingCardProps> = ({
    BookingName,
    bookingPlatform,
    BookingAddress,
    BookingCity,
    BookingImage,
    checkInDate,
    checkOutDate,
    onViewClick,
    bookingOf,
    onDownloadClick,
}) => {
    return (
        <Box
            p={4}
            mt={4}
            border="1px"
            borderColor="#353436"
            borderRadius='12px' >

            <HStack justifyContent="space-between" alignItems={'flex-start'}>
                <HStack spacing={'13px'}>
                    <CheckCircleIcon color="#43a047" boxSize={6} />
                    <VStack align="start" spacing={0}>
                        <Text fontWeight="700" size={'lg_18'} >{bookingOf}</Text>
                        <Text fontSize="13px" color="#FFFFFFA1" fontWeight={'500'}>
                            via {bookingPlatform}
                        </Text>
                    </VStack>
                </HStack>
                <HStack spacing={2}>
                    <Button size="sm"
                        color={'#AE7CFF'}
                        borderColor={'#AE7CFFA1'}
                        variant="outline"
                        w='61px'
                        onClick={onViewClick}
                        borderRadius={'8px'}>
                        View
                    </Button>
                    <IconButton
                        aria-label="Download"
                        icon={<DownloadIcon />}
                        size="sm"
                        color="#AE7CFF"
                        variant="outline"
                        borderColor={'#AE7CFFA1'}
                        onClick={onDownloadClick}
                        borderRadius={'8px'}
                    />
                </HStack>
            </HStack>

            {(BookingName &&
                <>
                 <HStack mt={4}  alignItems="flex-start">
                <Image
                    mr='2'
                    src={BookingImage}
                    alt={BookingName}
                    boxSize="70px"
                    borderRadius="lg"
                />
                <VStack align="start" spacing={0}>
                    <Text fontWeight="bold" fontSize="lg" color={'#FFFFFFED'}>
                        {BookingName}
                    </Text>
                    <Text textDecoration={'underline'} fontSize="13px" color={'#FFFFFFED'}>{BookingAddress}</Text>
                    <Text textDecoration={'underline'} fontSize="13px" color={'#FFFFFFED'}>{BookingCity}</Text>
                </VStack>
            </HStack>

            <Text mt={4} fontSize="13px" color="#FFFFFFED" fontWeight={'bold'}>
                {checkInDate} - {checkOutDate}
            </Text>
            </>
            )}
        </Box>
    );
};

export default BookingCard;
