import { Flex, Image } from '@chakra-ui/react';
import TrCircleButton from './TrCircleButton';
import TrTransparentButton from './TrTransparentButton';
import TrButton from './TrButton';
import { pixels } from '../theme';

const ItenaryDetailFooter = () => {
	return (
		<Flex
			boxShadow='0px -2px 13.6px 0px rgba(0, 0, 0, 0.15)'
			width={pixels['700pixels']}
			py={pixels['16pixels']}
			justifyContent='space-between'
			alignItems='center'
			px='26px'>
			<TrCircleButton aria-label={'share'}>
				<Image src={'./icons/share.svg'} alt='' />
			</TrCircleButton>

			<Flex gap={pixels['16pixels']}>
				<TrTransparentButton color={'trBlackColor'}>Edit</TrTransparentButton>
				<TrButton>Save</TrButton>
			</Flex>
		</Flex>
	);
};

export default ItenaryDetailFooter;
