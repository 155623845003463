import { IconButton as ChakraButton, IconButtonProps } from '@chakra-ui/react';

const style = {
	p: 0,
	display: 'flex',
	width: '46px',
	height: '46px',
	borderRadius: '50%',
	border: '1px solid rgba(53, 54, 53, 0.20)',
	justifyContent: 'center',
	alignItems: 'center',
	background: '#5d5d5d',
	boxShadow: '0px -2px 13.6px 0px rgba(0, 0, 0, 0.15)',
};
const TrCircleButton = ({
	onClick,
	colorScheme,
	size,
	isDisabled,
	children,
	...rest
}: IconButtonProps) => {
	return (
		<ChakraButton
			_hover={{
				border: '1px solid #5d5d5d',
				background: 'linear-gradient(180deg, #5d5d5d 0%, #434343 100%)',
				boxShadow: '0px 4px 6.5px 0px rgba(0, 0, 0, 0.20)',
			}}
			_active={{
				opacity: 0.7,
				background: 'linear-gradient(180deg, #5d5d5d 0%, #434343 100%)',
			}}
			{...style}
			display={'flex'}
			onClick={onClick}
			colorScheme={colorScheme}
			size={size}
			isDisabled={isDisabled}
			{...rest}>
			{children}
		</ChakraButton>
	);
};

export default TrCircleButton;
