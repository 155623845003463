import React from "react";
import { Container } from "@chakra-ui/react";
import CardSlider from "../CardSlider";
import HomePageFooter from "../HomePageFooter";

const HomePage = () => {
  return (
    <Container bg={"#262626"} maxWidth={"100%"} p={"5%"}>
      <CardSlider />
      <HomePageFooter />
    </Container>
  );
};

export default HomePage;
