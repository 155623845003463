import { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import useAxios from '../hooks/useAxios';
import { pixels } from '../theme';
import { Flex, Image, useDisclosure } from '@chakra-ui/react';
import TrCircleButton from './TrCircleButton';
import ModalWithBackdrop from './ModalWithBackdrop';
import { useNavigate } from 'react-router-dom';
import SigninComponent from './SigninComponent';

const LikeAndShare = () => {
	const {
		searchDetail,
		currentVideoIndex,
		likeStatus,
		setLikeStatus,
		shareModal,
		likeSearches,
		setLikeSearches,
	}: any = useContext(AppContext);
	const { fetchData } = useAxios();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();
	const myAppUrl = process.env.REACT_APP_URL;
	const likeSearch = async () => {
		const token = localStorage.getItem('token');
		if (token) {
			const body = {
				name: searchDetail['videos'][currentVideoIndex].title,
				city: searchDetail['user_query_entity']?.query_entity,
				country: searchDetail['user_query_entity']?.country,
				description: searchDetail['videos'][currentVideoIndex].description,
				tags: searchDetail['videos'][currentVideoIndex].tags,
				url: searchDetail['videos'][currentVideoIndex].video_url,
				previewImage: searchDetail['videos'][currentVideoIndex].previewImage,
				highlights: searchDetail['videos'][currentVideoIndex].highlights,
				location: searchDetail['location'],
				flightStartingPrice:
					searchDetail['videos'][currentVideoIndex].flightStartingPrice || 0,
			};

			if (!likeStatus[currentVideoIndex]?.liked) {
				await fetchData({
					method: 'POST',
					url: 'user/likeSearch',
					options: {
						data: { ...body, token: localStorage.getItem('token') },
						headers: { 'Content-Type': 'application/json' },
					},
					auth: false,
				});
				const obj = likeStatus;
				obj[currentVideoIndex].liked = true;
				setLikeStatus(obj);

				setLikeSearches([...likeSearches, body]);
			} else {
				await fetchData({
					method: 'POST',
					url: 'user/dislikeSearch',
					options: {
						data: { ...body, token: localStorage.getItem('token') },
						headers: { 'Content-Type': 'application/json' },
					},
					auth: false,
				});
				const obj = likeStatus;
				obj[currentVideoIndex].liked = false;
				setLikeStatus(obj);

				// removing the search from current likeSearches
				const tempLikeSearches = [];
				for (let search of likeSearches) {
					if (!(search['url'] === searchDetail['url'])) {
						tempLikeSearches.push(search);
					}
				}
				setLikeSearches(tempLikeSearches);
			}
		} else {
			onOpen();
		}
	};

	const handleShareClick = (title?: any, url?: any) => {
		console.log('modal title', {
			url: encodeURI(url),
			title: title,
		});
		shareModal.handleShareClick(encodeURI(url), title);
	};
	return (
		<Flex gap={pixels['12pixels']}>
			<ModalWithBackdrop
				isOpen={isOpen}
				onClose={() => {
					onClose();
					navigate('/home');
				}}>
				<SigninComponent />
			</ModalWithBackdrop>
			<TrCircleButton
				size={'32px'}
				sx={{
					height: {
						base: '32px !important',
						lg: '32px !important',
					},
					width: {
						base: '32px !important',
						lg: '32px !important',
					},
				}}
				aria-label='like'
				onClick={(e) => {
					e.stopPropagation();
					likeSearch();
				}}>
				{likeStatus[currentVideoIndex]?.liked === true ? (
					<Image
						sx={{
							h: { base: '15px', lg: '15px' },
							w: { base: '15px', lg: '15px' },
						}}
						src='/icons/like.svg'
						alt='like'
					/>
				) : (
					<Image
						sx={{
							h: { base: '15px', lg: '15px' },
							w: { base: '15px', lg: '15px' },
						}}
						src='/icons/heart-white.svg'
						alt='like'
					/>
				)}
			</TrCircleButton>
			<TrCircleButton
				size={'32px'}
				sx={{
					height: {
						base: '32px !important',
						lg: '32px !important',
					},
					width: {
						base: '32px !important',
						lg: '32px !important',
					},
				}}
				onClick={(e) => {
					console.log('app url', myAppUrl);
					e.stopPropagation();
					const link = `${myAppUrl}/search?query=${searchDetail?.entities[currentVideoIndex]?.title}`;
					console.log('link', link);
					handleShareClick(
						searchDetail?.entities[currentVideoIndex]?.title,
						link,
					);
				}}
				aria-label='share'>
				<Image
					sx={{
						h: { base: '15px', lg: '15px' },
						w: { base: '15px', lg: '15px' },
					}}
					src='/icons/share2.svg'
					alt='share'
				/>
			</TrCircleButton>
		</Flex>
	);
};

export default LikeAndShare;
