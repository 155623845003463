import { Badge, BadgeProps } from '@chakra-ui/react';
import React from 'react';
import { pixels } from '../theme';

interface TrBadgeProps extends BadgeProps {
	children: React.ReactNode;
}

const TrBadge: React.FC<TrBadgeProps> = ({ children, ...props }) => {
	return (
		<Badge
			textTransform={'capitalize'}
			borderRadius='99px'
			border='1px solid rgba(100, 100, 100, 0.33)'
			background='rgba(57, 56, 59, 0.33)'
			backdropFilter='blur(2px)'
			display='flex'
			padding={{ 'base': '8px 16px', 'lg': '13px 28px', '2xl': '16px 32px' }}
			justifyContent='center'
			alignItems='center'
			color='rgba(255, 255, 255, 0.83)'
			textAlign='center'
			fontFamily='Poppins'
			fontSize={{ 'base': pixels['14pixels'], 'lg': '14px', '2xl': '20px' }}
			fontStyle='normal'
			fontWeight='700'
			lineHeight='18px'
			{...props}>
			{children}
		</Badge>
	);
};

export default TrBadge;
