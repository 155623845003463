import 'react-phone-number-input/style.css';
import './App.css';
import type { RouteObject } from 'react-router-dom';
import { Link, useRoutes } from 'react-router-dom';
import Home from './pages/Home';
import { Box, useColorModeValue } from '@chakra-ui/react';
import Signup from './pages/Signup';
import Signin from './pages/Signin';
import EnterVerficationPage from './pages/EnterVerificationCodePage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import AppLayout from './components/Layouts';
import HomePageDesktop from './components/HomeDesktopDesign';
import NewAppLayout from './components/Layouts/NewAppLayut';
import HomePage from './components/Layouts/HomePage';
import SearchPage from './pages/SearchPage';
import MyBucketList from './pages/MyBucketList';
// import { SearchDetailContext } from './context/searchDetailContext';
import { AppContextProvider } from './context/AppContext';
import AuthCallback from './pages/AuthCallback';
import BucketCardDetail from './components/BucketCardDetail';
import Homepage from './pages/Homepage';
import JoinWaitList from './pages/JoinWaitList';
import ListItinerary from './components/CreateItinary/ListItinerary';
import Itinerary from './pages/Itinerary';

function App() {
	let routes: RouteObject[] = [
		{
			path: '/',
			element: <AppLayout />,
			children: [
				{ index: true, element: <Homepage /> },
				{ index: false, path: 'join', element: <JoinWaitList /> },
			],
		},
		{
			path: '/home',
			element: <NewAppLayout />,
			children: [
				{ index: true, element: <HomePageDesktop /> },
				{ index: false, path: 'search', element: <SearchPage /> },
				{ index: false, path: 'itineraries', element: <ListItinerary /> },
				{ index: false, path: 'itineraries/:id', element: <Itinerary /> },
				{ index: false, path: 'mybucketlist', element: <MyBucketList /> },
				{
					index: false,
					path: 'bucketCardDetail',
					element: <BucketCardDetail />,
				},
			],
		},

		{ index: true, path: '/home/auth/:token', element: <AuthCallback /> },
		// {
		//   path: "/mybucketlist",
		//   children: [{ index: true, element: <MyBucketList /> }],
		// },
		{
			path: '/home/mobile',
			element: <AppLayout />,
			children: [{ index: true, element: <Home /> }],
		},
		// {
		// 	path: '/auth/:token',
		// 	children: [{ index: true, element: <AuthCallback /> }],
		// },
		{
			path: '/verify',
			children: [{ index: true, element: <EnterVerficationPage /> }],
		},
		{
			path: '/verify/change-password',
			children: [{ index: true, element: <ChangePasswordPage /> }],
		},
		{
			path: '/signup',
			children: [{ index: true, element: <Signup /> }],
		},
		{
			path: '/signin',
			children: [{ index: true, element: <Signin /> }],
		},
		{
			path: '/influencer/homepage',
			children: [{ index: true, element: <HomePage /> }],
		},
		{
			path: '/forgot-password',
			children: [{ index: true, element: <ForgotPasswordPage /> }],
		},

		{ path: '/*', element: <NoMatch /> },
	];

	let element = useRoutes(routes);
	const bg = useColorModeValue('trBgColor', 'trBackgroundColor');
	return (
		<Box
			bg={bg}
			overflowY={'auto'}
			minHeight='100vh'
			display='flex'
			flexDirection='column'
			sx={{
				scrollbarWidth: 'none',
			}}
			width={'100%'}>
			{' '}
			<AppContextProvider>{element}</AppContextProvider>
		</Box>
	);
}

export default App;

function NoMatch() {
	return (
		<div>
			<h2>It looks like you're lost...</h2>
			<p>
				<Link to='/'>Go to the home page</Link>
			</p>
		</div>
	);
}
