import { Card, CardBody, Heading, Image, Stack, Text } from '@chakra-ui/react';
import { pixels } from '../../theme';

const ItineraryCardSlice = ({ activity }: any) => {
	return (
		<Card w={pixels['155pixels']} borderRadius={pixels['16pixels']}>
			<CardBody p={pixels['5pixels']}>
				<Image
					src='https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80'
					alt='Green double couch with wooden legs'
					borderRadius='xl'
				/>
				<Stack mt='6' spacing='3'>
					<Heading size='sm'>{activity?.accommodation}</Heading>
					<Text fontSize={'sm'}>{activity.activities[0]}</Text>
					<Text
						fontWeight={'700'}
						color='background: rgba(66, 66, 66, 1)'
						fontSize='21px'>
						~ 8000 LAC
					</Text>
				</Stack>
			</CardBody>
		</Card>
	);
};

export default ItineraryCardSlice;
