import React from 'react';
import { Link as ChakraLink } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const TrLink: React.FC<{ to: string; children: React.ReactNode }> = ({
	to,
	children,
}) => {
	return (
		<ChakraLink
			as={RouterLink}
			to={to}
			textDecoration='none !important'
			outline='none'
			color='#000'
			fontFamily='Roboto'
			_hover={{ textDecoration: 'none', color: 'trPrimaryColor' }}>
			{children}
		</ChakraLink>
	);
};

export default TrLink;
