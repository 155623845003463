import {
  Flex,
  useColorModeValue,
  Heading,
  Image,
  Text,
  Container,
  HStack,
} from "@chakra-ui/react";

const MoreAboutPlaceSection = () => {
  const data = [
    {
      ImageUrl:
        "https://media.gettyimages.com/id/1345280126/photo/uk-london-aerial-view-of-tower-bridge-over-river-thames-at-sunset.jpg?s=612x612&w=gi&k=20&c=DsXt1qQxIzwQHZCLxLt_FMO2pcJcoHsMr_sAjtJ8d7w=",
      PlaceDescription: "Go Mudlarking along the Thames",
      ChannelName: "Top 10 Places",
      ChannelLogoUrl:
        "https://yt3.googleusercontent.com/ggvnekCrh4Vb2gpQJTZdYw-XNA8qxNvwxnNWExM2aGz5XUGqmc10rEizQxqcIst8kD5bhDm1Z4Q=s176-c-k-c0x00ffffff-no-rj",
    },
    {
      ImageUrl:
        "https://media.istockphoto.com/id/952375942/photo/westminster-parliament-big-ben-and-the-thames-with-blue-sky.jpg?s=612x612&w=0&k=20&c=aDsTm010b3iqggED8ygVA2KijYE6gE1ubvh4tUTR79s=",
      PlaceDescription: "Go Mudlarking along the Thames",
      ChannelName: "Channel Name",
      ChannelLogoUrl:
        "https://yt3.googleusercontent.com/uxtNaM5aWylFG8wTizKu6aeGYyPsknNrWh1jIh4m30ORyPOFfhWRgyGsiUoZU5MIQgYDqda3Og=s176-c-k-c0x00ffffff-no-rj",
    },
  ];

  const textColor = useColorModeValue("trTextColor");
  return (
    <Flex direction={"column"} p={"10px 15px"} color={textColor}>
      <Flex dir="row" justify={"space-between"}>
        <Heading fontSize={"lg"}>See more about London</Heading>
        <button>View all</button>
      </Flex>

      {data.map((value, index) => {
        return (
          <Container p={"0px"} pt={"15px"} key={index}>
            <Image
              h={"120px"}
              borderRadius={"lg"}
              width={"full"}
              src={value["ImageUrl"]}
            />
            <Text fontSize={"md"} mt={"5px"} fontWeight={"bold"}>
              {value["PlaceDescription"]}
            </Text>
            <HStack pt={"5px"}>
              <Image
                boxSize={"20px"}
                borderRadius={"full"}
                src={value["ChannelLogoUrl"]}
                alt="Image Preview"
              />
              <Text fontSize={"lg"} fontWeight={"bold"}>
                {value["ChannelName"]}
              </Text>
            </HStack>
          </Container>
        );
      })}
    </Flex>
  );
};

export default MoreAboutPlaceSection;
