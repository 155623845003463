import { Flex, Text } from '@chakra-ui/react';
import CountryContentBox from './CountryContentBox';
import { pixels } from '../../theme';

const WeatherDetail = ({ weather }: any) => {
	return (
		<CountryContentBox>
			<Flex
				fontFamily='Poppins'
				w='100%'
				justifyContent='space-between'
				alignItems='center'>
				<Flex
					gap={pixels['10pixels']}
					flexDir='column'
					alignItems={'self-start'}>
					<Text
						fontSize={{ base: pixels['16pixels'], lg: pixels['24pixels'] }}
						fontWeight={700}
						lineHeight='24px'
						textAlign='center'>
						Weather
					</Text>
					<Text
						fontSize={{ base: pixels['18pixels'], lg: pixels['30pixels'] }}
						fontWeight={700}
						color='#161517'
						lineHeight={{ base: '110%', lg: '50.25px' }}
						textAlign='center'>
						{weather?.temperature}℃
					</Text>
					<Text
						noOfLines={3}
						fontFamily='Poppins'
						fontSize={{ base: pixels['10pixels'], lg: pixels['16pixels'] }}
						fontWeight={500}
						lineHeight='13px'
						color='#16151754'
						textAlign='left'>
						{weather?.description}
					</Text>
				</Flex>

				<Text
					fontSize={{ base: pixels['12pixels'], lg: pixels['16pixels'] }}
					fontWeight={500}
					noOfLines={6}
					color='#161517'
					lineHeight='23px'
					maxWidth={{ base: '150px', lg: '190px' }}
					textAlign='left'>
					{weather?.yearlyDescription}
				</Text>
			</Flex>
		</CountryContentBox>
	);
};

export default WeatherDetail;
