import React, { useState, useEffect } from 'react';
import {
	Flex,
	Box,
	Text,
	Divider,
	HStack,
	Image,
	Alert,
	AlertIcon,
} from '@chakra-ui/react';
import TraverseBlackLogo from './TraverseBlackLogo';
import SocialAuthButton from './Buttons/SocialAuthButtons';
import { pixels } from '../theme';
import PhoneNumberInput from './PhoneNumberInput';
import TrPrimaryButton from './TrPrimaryButton';
import useAxios from '../hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import { isValidPhoneNumber } from 'react-phone-number-input';
import VerifyCode from './VerrifyCode';

interface LoginFields {
	phone_number: string;
}

const validateFields = (values: LoginFields) => {
	const errors: Partial<LoginFields> = {};

	if (!values.phone_number) {
		errors.phone_number = 'Phone number is required';
	} else if (!isValidPhoneNumber(values.phone_number)) {
		errors.phone_number = 'Invalid phone number';
	}

	return errors;
};

const SigninComponent: React.FC = () => {
	const navigate = useNavigate();
	const { loading, fetchData } = useAxios();
	const { loading: loading2, fetchData: fetchData2 } = useAxios();
	const initLoginFields: LoginFields = { phone_number: '' };
	const [loginFields, setLoginFields] = useState<LoginFields>(initLoginFields);
	const [errors, setErrors] = useState<Partial<LoginFields>>({});
	const [serverError, setServerError] = useState<string>('');
	const [showVerifyCodeForm, setShowVerifyCodeForm] = useState<boolean>(false);

	useEffect(() => {
		const handleAuthCallback = () => {
			const token = localStorage.getItem('token');
			if (token) {
				// alert(token);
				// localStorage.removeItem('token');
				// navigate('/');
			}
		};

		window.addEventListener('storage', handleAuthCallback);

		return () => {
			window.removeEventListener('storage', handleAuthCallback);
		};
	}, [navigate]);

	const setPhoneValue = (value: string) => {
		setErrors({ ...errors, phone_number: '' });
		setLoginFields({ ...loginFields, phone_number: value });
	};

	const callbackActionAfterLogin = (token: string) => {
		if (token) {
			localStorage.setItem('token', token);
		}
		window.location.reload();
	};

	const login = async () => {
		const formErrors = validateFields(loginFields);

		if (Object.keys(formErrors).length === 0) {
			const { statusCode, error } = await fetchData({
				method: 'POST',
				url: 'login/phone/request-code',
				options: {
					data: loginFields,
					headers: { 'Content-Type': 'application/json' },
				},
				auth: false,
			});
			if (statusCode === 200) {
				// setErrors({ initLoginFields });
				setServerError('');
				setShowVerifyCodeForm(true);
			} else {
				setServerError(JSON.stringify(error));
			}
		} else {
			setErrors(formErrors);
		}
	};

	const socialAuth = async (name: string) => {
		try {
			const response = await fetchData2({
				method: 'GET',
				url: `authorize/${name}`,
				options: {
					headers: { 'Content-Type': 'application/json' },
				},
				auth: false,
			});
			if (response?.data?.auth_url) {
				window.location.href = response.data.auth_url;
			} else {
				setServerError('Failed to get authorization URL');
			}
		} catch (error) {
			setServerError('Failed to get authorization URL');
		}
	};

	return (
		<Flex
			sx={{
				borderRadius: { base: 0, lg: '34px' },
				width: { base: '100%', lg: '1230px' },
				height: { base: '100%', lg: '702px' },
				background: 'transparent',
			}}>
			<Image
				display={{ base: 'none', lg: 'unset' }}
				borderRadius='33px 0px 0px 33px'
				src='/images/left-side-login-background.png'
				alt='login-to-traverse-image'
			/>
			<Flex
				w='100%'
				alignItems={'center'}
				flexDir={'column'}
				p={pixels['20pixels']}
				pt={{ base: pixels['30pixels'], lg: '115px' }}>
				<Box
					w={{ base: '100%', lg: '420px' }}
					mx='auto'
					display={'flex'}
					alignItems={'center'}
					flexDir={'column'}
					gap={pixels['20pixels']}>
					{serverError && (
						<Alert status='error'>
							<AlertIcon />
							{serverError}
						</Alert>
					)}
					<Box mx='auto'>
						<TraverseBlackLogo />
					</Box>

					<Text
						sx={{
							color: 'rgba(22, 21, 23, 0.63)',
							fontFamily: 'Poppins',
							fontSize: '20px',
							fontStyle: 'normal',
							fontWeight: '500',
							lineHeight: '18px',
							mx: 'auto',
						}}>
						{!showVerifyCodeForm
							? 'Please log in to continue'
							: 'Please type verification code'}
					</Text>
					{!showVerifyCodeForm ? (
						<>
							<SocialAuthButton
								onClick={() => socialAuth('facebook')}
								colorScheme='trFacebookBlue'
								iconUrl='/icons/facebook.svg'
								name='facebook'
								isLoading={loading2}
							/>

							<SocialAuthButton
								colorScheme='trInstagramBlack'
								iconUrl='/icons/instagram.svg'
								name='instagram'
								onClick={() => socialAuth('instagram')}
								isLoading={loading2}
							/>

							<HStack borderColor={'trGrey'}>
								<Divider w={'118px'} borderColor={'trGrey'} />
								<Text
									mx={pixels['10pixels']}
									textStyle='sm'
									whiteSpace='nowrap'
									color='trGreyFont63'>
									or
								</Text>
								<Divider w={'118px'} borderColor={'trGrey'} />
							</HStack>

							<PhoneNumberInput
								isInvalid={!!errors.phone_number}
								isDisable={loading || loading2}
								value={loginFields.phone_number}
								onChange={(value) => setPhoneValue(value)}
							/>

							<TrPrimaryButton
								isDisabled={loading}
								onClick={login}
								isLoading={loading2}>
								Send OTP
							</TrPrimaryButton>
						</>
					) : (
						<VerifyCode
							onVerifySuccess={callbackActionAfterLogin}
							phone_number={loginFields.phone_number}
						/>
					)}
				</Box>
			</Flex>
		</Flex>
	);
};

export default SigninComponent;
