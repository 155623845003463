import { Heading, Flex, useColorModeValue, HStack } from '@chakra-ui/react';
import MoreFromInfluecerCard from "./MoreFromInfluecerCard";

const MoreFromInfluencerSection = () => {
  const data = [
    {
      ImageUrl:
        "https://tse4.mm.bing.net/th?id=OIP.UEfYtAFskXL4ub9XIm6qkQHaEH&pid=Api&P=0&h=180",
      PlaceName: "London",
    },
    {
      ImageUrl:
        "https://tse4.mm.bing.net/th?id=OIP.DqIiSAlK51UM7FkcwAqf9AHaEK&pid=Api&P=0&h=180",
      PlaceName: "Vitenam",
    },
    {
      ImageUrl:
        "https://tse1.mm.bing.net/th?id=OIP.CATJxFfHOVv_ijy6lCkViAHaE8&pid=Api&rs=1&c=1&qlt=95&w=161&h=107",
      PlaceName: "Indonesia",
    },
    {
      ImageUrl:
        "https://tse1.mm.bing.net/th?id=OIP.pEgfM18iTdiJvVAwsMGbNgHaE8&pid=Api&P=0&h=180",
      PlaceName: "Germany",
    },
    {
      ImageUrl:
        "https://images.adsttc.com/media/images/5d44/14fa/284d/d1fd/3a00/003d/large_jpg/eiffel-tower-in-paris-151-medium.jpg?1564742900",
      PlaceName: "Paris",
    },
  ];

  const style = {
    overflowX: "auto",
    scrollbarWidth: "none",
  };

  const textColor = useColorModeValue("trTextColor");
  return (
    <Flex direction={"column"} p={"10px 15px"} color={textColor}>
      <Flex dir="row" justify={"space-between"}>
        <Heading fontSize={"lg"}>See more from this influencer</Heading>
        <button>View all</button>
      </Flex>
      <HStack spacing={1} style={style} pt="10px">
        {data.map((value, index) => {
          return (
            <MoreFromInfluecerCard
              key={index}
              placeName={value["PlaceName"]}
              imageUrl={value["ImageUrl"]}
            />
          );
        })}
      </HStack>
    </Flex>
  );
};

export default MoreFromInfluencerSection;
