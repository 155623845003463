import React from 'react';
import {
  Box,
  Text,
  Flex,
  Button,
} from '@chakra-ui/react';
import LocationBox from './LocationBox';

interface TripCardProps {
  destination: string;
  dates: string;
  numberOfAdults: number;
  onEdit: () => void;
  onAddTickets: () => void;
}

const TripCard: React.FC<TripCardProps> = ({
  destination,
  dates,
  numberOfAdults,
  onEdit,
  onAddTickets,
}) => {
  return (
    <Flex
      bg='none'
      align={['default','default','center']}
      color="white"
      p={4}
      direction={['column', 'column', 'row']}
      boxShadow={["lg", "lg", 'none']}
      width="100%"
    >
      {/* Name detail box */}

      <LocationBox
        destination={destination}
        dates={dates}
        numberOfAdults={numberOfAdults}
        onEdit={()=>console.log("Edit Button Clicked")}
      />
      <Box
        visibility={["visible", "visible", "hidden"]}
        borderBottom="1px"
        borderColor="#FFFFFF21"
        borderStyle="dashed"
        mt={4}
      />
      {/* Flight booking text */}
      <Flex mt={[4, 4, 0]}
        bg={['none', 'none', '#39383B54']}
        px={['0px', '0px', '22px']}
        py={['0px', '0px', '16px']}
        flexDirection="row"
        alignItems="start"
        maxH={['35px', '35px', '64px']}
        justifyContent={"space-between"}
        ml={[0, 0, '13px']}
        borderRadius={['0', '0', '12px']}
        border={['0px', '0px', '1px']}
        borderColor={["none", "none", "#64646454"]}
      >
        <Text color="#FFFFFF54" fontSize="13px" noOfLines={2} >
          Add flight bookings & hotel bookings, <br /> insurances etc
        </Text>
        <Button
          fontSize='13px'
          variant="link"
          maxH={'78px'}
          mr={4}
          ml={[1, 1, 4]}
          color="#AE7CFF"
          onClick={onAddTickets}
        >
          + Add tickets
        </Button>
      </Flex>
      <Box
        visibility={["visible", "visible", "hidden"]}
        borderBottom="1px"
        borderColor="#FFFFFF21"
        borderStyle="dashed"
        mt={4}
      />
    </Flex>
  );
};

export default TripCard;
