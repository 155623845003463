import React, { useState } from 'react';
import {
	Box,
	Container,
	Heading,
	Stack,
	Alert,
	AlertIcon,
} from '@chakra-ui/react';
import { pixels } from '../theme';
import TrButton from '../components/TrButton';
import TraverseLogo from '../components/TraverseLogo';
import useAxios from '../hooks/useAxios';
import { PasswordField } from '../components/TrPasswordField';

const validateFields = (values: any) => {
	const errors: any = {};

	if (!values.password) {
		errors.password = 'Password is required';
	}

	if (!values.confirm_password) {
		errors.confirm_password = 'Please confirm your password';
	} else if (values.password !== values.confirm_password) {
		errors.confirm_password = 'Passwords do not match';
	}

	return errors;
};

const ChangePasswordPage: React.FC = () => {
	const { data, error, loading, fetchData, statusCode } = useAxios();
	const initLoginFields = {
		first_name: '',
		last_name: '',
		phone_number: '',
		password: '',
		confirm_password: '',
	};
	const [loginFields, setLoginFields] = useState(initLoginFields);
	const [errors, setErrors] = useState<any>({});
	const [serverError, setServerError] = useState<string>('');

	const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setLoginFields({
			...loginFields,
			[name]: value,
		});
	};

	const login = async () => {
		const formErrors = validateFields(loginFields);

		if (Object.keys(formErrors).length === 0) {
			fetchData({
				method: 'POST',
				url: 'login',
				options: {
					data: loginFields,
					headers: { 'Content-Type': 'application/json' },
				},
				auth: false,
			});

			if (statusCode === 201) {
				console.log(data);
				setErrors({});
				setLoginFields(initLoginFields);
			} else {
				setServerError(JSON.stringify(error));
			}
		} else {
			setErrors(formErrors);
		}
	};

	return (
		<Container
			color='trTextColor'
			background={'#fcfdfe'}
			h='100vh'
			maxW='lg'
			py={{ base: '12', md: '24' }}
			px={{ base: '0', sm: '8' }}>
			<Stack spacing='8'>
				<Stack spacing='6'>
					<Stack spacing={{ base: '2', md: '3' }} textAlign='center'>
						<Box mb={pixels['20pixels']}>
							<TraverseLogo />
						</Box>
						<Heading fontSize={{ base: pixels['30pixels'] }}>
							Change your password
						</Heading>
					</Stack>
				</Stack>
				{serverError ? (
					<Alert status='error'>
						<AlertIcon />
						{serverError || serverError !== null
							? serverError
							: 'There was an error processing your request'}
					</Alert>
				) : (
					''
				)}
				<Box
					backgroundColor={'trWhiteColor'}
					py={{ base: '0', sm: '8' }}
					px={{ base: '4', sm: '10' }}
					bg={{ base: 'transparent', sm: 'bg.surface' }}
					boxShadow={{ base: 'none', sm: 'md' }}
					borderRadius={{ base: 'none', sm: 'xl' }}>
					<Stack spacing='6'>
						<PasswordField
							label='Password'
							errorMessage={errors.password}
							isInvalid={errors.password}
							name='password'
							value={loginFields.password}
							onChange={handleFormChange}
						/>

						<PasswordField
							label='Confirm Password'
							errorMessage={errors.confirm_password}
							isInvalid={errors.confirm_password}
							name='confirm_password'
							value={loginFields.confirm_password}
							onChange={handleFormChange}
						/>

						<Stack spacing='6'>
							<TrButton
								onClick={login}
								isLoading={loading}
								isDisabled={loading}>
								Change Password
							</TrButton>
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</Container>
	);
};

export default ChangePasswordPage;
