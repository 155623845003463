import { Flex, Image } from '@chakra-ui/react';
import { pixels } from '../theme';
import TrOutlineButton from './TrOutlineButton';
import { useEffect, useState } from 'react';

const ScrollNextButton = () => {
	const [isAtTop, setIsAtTop] = useState(true);

	useEffect(() => {
		const handleScroll = () => {
			setIsAtTop(window.scrollY === 0);
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<>
			{isAtTop && (
				<Flex
					display={{ base: 'none', md: 'flex' }}
					mx='auto'
					_hover={{ opacity: 0.7 }}
					_active={{ opacity: 0.9 }}
					gap={pixels['20pixels']}>
					<TrOutlineButton
						sx={{
							fontSize: { 'base': '13px', '2xl': '16px' },
							w: { 'base': '159px', '2xl': '196px' },
							h: { 'base': '27px', '2xl': '34px' },
						}}
						borderRadius={'99px'}
						px={pixels['20pixels']}>
						See more details
					</TrOutlineButton>
					<Image
						sx={{
							w: { 'base': '9px', '2xl': '11px' },
							h: { 'base': '14px', '2xl': '17px' },
							my: 'auto',
						}}
						src='/icons/arrow_down.svg'
						alt='arrow_down'
					/>
				</Flex>
			)}
		</>
	);
};

export default ScrollNextButton;
